import React from 'react';
import styles from './Spotlight.module.scss';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

class Spotlight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
		this.myRef = React.createRef();
	}

	toggleContents(e) {
		this.setState((state, props) => ({
			isOpen: !state.isOpen
		}));

		return true;
	}

	navigateToTop() {
		this.myRef.current.scrollIntoView();
	}

	render() {
		let variationStyle = '';

		if('image_above' === this.props.variation) {
			variationStyle = styles.ImageAbove;
		}

		if('image_right' === this.props.variation) {
			variationStyle = styles.ImageRight;
		}

		if('image_left' === this.props.variation) {
			variationStyle = styles.ImageLeft;
		}

		if('no_image_add_border' === this.props.variation) {
			variationStyle = styles.NoImageAddBorder;
		}

		if('small_padding' === this.props.variation) {
			variationStyle = styles.SmallPadding;
		}

		if('no_padding' === this.props.variation) {
			variationStyle = styles.NoPadding;
		}

		if('header_image' ===  this.props.variation) {
			variationStyle = styles.HeaderImage;
		}

		let spacingStyle = '';

		if( this.props.stacked ) {
			spacingStyle = styles.TightText;
		}

		let tightTop = '';

		if( this.props.tightTop ) {
			tightTop = styles.TightTop;
		}

		const showTextContent = !!this.props.children;

		let headerEl;
		if (this.props.header) {
			headerEl = <h2 className={styles.Header}>{this.props.header}</h2>;
		}

		let subHeaderEl;
		if (this.props.header) {
			subHeaderEl = <h3 className={styles.SubHeader}>{this.props.subheader}</h3>;
		}

		let fadeStyle = '';
		let maxHeight;
		if ( this.props.fadeToggleAfter ) {
			fadeStyle = styles.HasFade;
			maxHeight = this.state.isOpen ? 'none' : this.props.fadeToggleAfter;
		}

		return (
			<div className={styles.Spotlight + ' ' + variationStyle + ' ' + spacingStyle + ' ' + tightTop} style={{ 
				backgroundColor: this.props.color,
				color: this.props.textColor || '#fff',
				marginTop: this.props.stacked && 0,
				marginBottom: this.props.stacked && 0,
				borderBottom: this.props.stacked && '1px solid #fff',
			}}>
				{this.props.image && <div className={styles.ImageContainer} style={{
					padding: this.props.imagePadding,
					backgroundColor: this.props.imageBg
				}}>
					<div className={styles.Image + ' text-center show-for-desktop'}><img src={this.props.image} alt={this.props.imageAlt} /></div>
					<div className={styles.Image + ' image text-center show-for-mobile'}><img src={this.props.mobileImage} alt={this.props.imageAlt} /></div>
				</div>}

				{showTextContent && <div className={styles.Text + ' ' + fadeStyle} style={{maxHeight: maxHeight}} ref={this.myRef}>
					{headerEl}
					{subHeaderEl}

					{this.props.children}

					{this.props.fadeToggleAfter && 
						<div className={styles.Fade}>
							{!this.state.isOpen && <AddCircleOutlineIcon className={styles.Icon} onClick={() => this.toggleContents()} />}
							{this.state.isOpen && <HighlightOffIcon className={styles.Icon} onClick={() => this.toggleContents() && this.navigateToTop()} />}
						</div>
					}
				</div>}
			</div>
		);
	}
}

export default Spotlight;
