import React from 'react';
import ExpandableSection from '../ExpandableSection/ExpandableSection.js';

import styles from './CaseStudy.module.scss';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

class CaseStudy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	toggleContents(e) {
		if(this.props.onToggle) {
			this.props.onToggle(e);
		}

		this.setState((state, props) => ({
			isOpen: !state.isOpen
		}));
	}

	render() {
		const isActive = this.props.active;

		return (
			<div className={styles.CaseStudy}>
				<div className="show-for-desktop">
					<h2 className={styles.Title + ' ' + (isActive ? styles.Active : '')}>
						{this.props.title}
					</h2>

					<div className={styles.Image + ' ' + (isActive ? styles.Active : '')}>
						<img
							className="show-for-desktop"
							src={this.props.image} 
							alt="" 
							onClick={() => this.toggleContents(this.props.content)} 
						/>

						{!isActive && <AddCircleOutlineIcon className={styles.Icon} onClick={() => this.toggleContents(this.props.content)} />}
					</div>
				</div>

				{/* Single content block for mobile view only */}
				<div className={styles.MobileView + ' show-for-mobile'}>
					<ExpandableSection title={this.props.title}>
						<img 
							className={styles.MobileImage}
							src={this.props.image} 
							alt="" 
						/>
						{this.props.content}
					</ExpandableSection>
				</div>
			</div>
		);
	}
}

export default CaseStudy;
