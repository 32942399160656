import React from 'react';

import Hero from '../../components/Hero/Hero.js';
import hero_image from '../../images/hero.jpg';

class HomeHero extends React.Component {
	render() {
		return (
			
			<Hero image={hero_image}>
				<h1 className="page-title">Elevating Gender Equality in COVID-19 Economic Recovery</h1>
				<h2 className="header-h2 no-border">An evidence synthesis and call for policy action</h2>
			</Hero>
				
		);
	}
}

export default HomeHero;
