import React from 'react';

class ContainedContent extends React.Component {
	render() {
		return (
			<div className="App-content">
				<div className="row">
					<div className="col-2"></div>
					<div className="col-8">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default ContainedContent;
