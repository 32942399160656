import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { useState } from 'react';
import Header from './components/Header/Header.js';
import Sidebar from './components/Sidebar/Sidebar.js';

import HomeHero from './pages/Home/HomeHero.js';
import Home from './pages/Home/Home.js';

import Caregiving from './pages/Caregiving/Caregiving.js';

import './Variables.scss';
import './App.scss';
import './Fonts.scss';
import './Grid.scss';
import './Typography.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
	const [navLabel, setNavLabel] = useState('');

	const onSidebarUpdate = (text) => {
		if (!text) {
			// Default text
			return setNavLabel('');
		}

		setNavLabel(text);
	};
	
  	return (
		<Router>
			<div className="App">
				<Header navLabel={navLabel} />

				<div className="App-content">
					<Switch>
						<Route exact path="/">
							<HomeHero />
						</Route>
						<Route path="/caregiving">

						</Route>
						<Route component={ Home } />
					</Switch>

					<div className="row App-content-inner">
						<Sidebar onUpdate={onSidebarUpdate} />

						<Switch>
							<Route exact path="/">
								<Home />
							</Route>
							<Route path="/caregiving">
								<Caregiving />
							</Route>
							<Route component={ Home } />
						</Switch>

					</div>
				</div>
			</div>
		</Router>
  	);
}

export default App;
