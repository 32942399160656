import React from 'react';
import FullWidthSection from '../FullWidthSection/FullWidthSection.js';
import ContainedContent from '../FullWidthSection/ContainedContent.js';

import styles from './SummaryOfCareCrisis.module.scss';

class SummaryOfCareCrisis extends React.Component {
	render() {
		return (
			<FullWidthSection className={styles.SummaryOfCareCrisis}>
				<ContainedContent>
					<p className={styles.Button}><a href="/caregiving">Summary of the Care Crisis</a></p>
				</ContainedContent>
			</FullWidthSection>
		);
	}
}

export default SummaryOfCareCrisis;
