import React from "react";
import SlickSlider from "react-slick";
import styles from './SlickCustomStyle.module.scss';

import julia_image from '../../images/podcast/julia-arnold-podcast-quote.jpg';
import namra_image from '../../images/podcast/Namara-Eve-Uganda-family-podcast-quote.jpg';
import monika_image from '../../images/podcast/Monika-Banerjee-podcast-quote.jpg';
import deepa_image from '../../images/podcast/Deepa-informal-worker-podcast-quote.jpg';
import sabrina_image from '../../images/podcast/Sabrina-Habib-podcast-quote.jpg';
import podcast_icon from '../../images/Hero-podcast-women-gender-foreign-policy-gates-foundation-logo.jpg';

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    adaptiveHeight: true
};

class Slider extends React.Component {
	render() {
          return (
            <div className={styles.SliderWrapper} style={{ 
				backgroundColor: this.props.color
			}}>

                <SlickSlider {...settings}>

                    <div className="Podcast">
                        <div className="Podcast__top">
                            <div className="left-icon">
                                <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                                    <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                                </a>
                            </div>
                            <div className="right-content">
                                <h3><a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">Listen to the HERO Podcast</a></h3>
                            </div>
                        </div>
                        <h4><strong>In Uganda</strong>, women are finding greater economic freedom through pooling their money and finding strength as a collective &mdash; and challenging gender dynamics within their own homes.</h4>
                        <div className="Podcast__inner">
                            <div className="Podcast__left">
                                <img src={namra_image} alt="Namara Eve, Household Dialogues Participant in Uganda" />
                            </div>
                            <div className="Podcast__right">
                                <p className="quote">&ldquo;What I can advise my fellow women is start saving the little they can. I was badly off, but now I'm doing well. My first goal is to complete the house that we are constructing. The second goal is to keep our children in school, so we are paying for them, the school fees. And the third goal is to increase and buy more land so that we can add on what we have.&rdquo;</p>
                                <p className="author">&mdash;Namara Eve, Household Dialogues Participant in Uganda</p>
                            </div>
                        </div>
                    </div>

                    <div className="Podcast">
                        <div className="Podcast__top">
                            <div className="left-icon">
                                <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                                    <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                                </a>
                            </div>
                            <div className="right-content">
                                <h3><a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">Listen to the HERO Podcast</a></h3>
                            </div>
                        </div>
                        <h4><strong>In Uganda</strong>, women are finding greater economic freedom through pooling their money and finding strength as a collective &mdash; and challenging gender dynamics within their own homes.</h4>
                        <div className="Podcast__inner">
                            <div className="Podcast__left">
                                <img src={julia_image} alt="Julia Arnold, Senior Research Director, Center for Financial Inclusion" />
                            </div>
                            <div className="Podcast__right">
                                <p className="quote">&ldquo;What was amazing was that over the course of these <a href="https://www.careevaluations.org/evaluation/digital-subwallets-and-household-dialogues/" target="_blank" rel="noreferrer">household dialogues</a>, during this study, they saw tangible changes in men's savings, behavior and men's communication.&rdquo;</p>
                                <p className="author">&mdash;Julia Arnold, Senior Research Director, Center for Financial Inclusion </p>
                            </div>
                        </div>
                    </div>

                    <div className="Podcast">
                        <div className="Podcast__top">
                            <div className="left-icon">
                                <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                                    <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                                </a>
                            </div>
                            <div className="right-content">
                                <h3><a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">Listen to the HERO Podcast</a></h3>
                            </div>
                        </div>
                        <h4><strong>In India</strong>, a push to bring more visibility to women in the informal economy is creating pressure to provide benefits and security to women.</h4>
                        <div className="Podcast__inner">
                            <div className="Podcast__left">
                                <img src={monika_image} alt="Monika Banerjee, Research Fellow, Institute of Social Studies Trust, New Delhi" />
                            </div>
                            <div className="Podcast__right">
                                <p className="quote">&ldquo;Ninety five percent of women are in the informal sector or they're choosing not to work at all...Until a couple of years back, there was not even recognition that they are workers. Now at least that the state is now pushed to recognize.&rdquo;</p>
                                <p className="author">&mdash;Monika Banerjee, Research Fellow, Institute of Social Studies Trust, New Delhi</p>
                            </div>
                        </div>
                    </div>

                    <div className="Podcast">
                        <div className="Podcast__top">
                            <div className="left-icon">
                                <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                                    <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                                </a>
                            </div>
                            <div className="right-content">
                                <h3><a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">Listen to the HERO Podcast</a></h3>
                            </div>
                        </div>
                        <h4><strong>In India</strong>, a push to bring more visibility to women in the informal economy is creating pressure to provide benefits and security to women.</h4>
                        <div className="Podcast__inner">
                            <div className="Podcast__left">
                                <img src={deepa_image} alt="Deepa, domestic worker" />
                            </div>
                            <div className="Podcast__right">
                                <p className="quote">&ldquo;What I like about my work is that people now identify me as a domestic worker. [Now,] they see me as someone who is able to manage her house nicely. I like this a lot. It's my identity.&rdquo;</p>
                                <p className="author">&mdash;Deepa (she has no last name), domestic worker and <a href="https://idwfed.org/en/affiliates/asia-pacific/sewa" target="_blank" rel="noreferrer">SEWA member</a></p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="Podcast">
                        <div className="Podcast__top">
                            <div className="left-icon">
                                <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                                    <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                                </a>
                            </div>
                            <div className="right-content">
                                <h3><a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">Listen to the HERO Podcast</a></h3>
                            </div>
                        </div>
                        <h4>And in <strong>Kenya</strong>, a new approach to franchising childcare centers is changing the lives of both mothers and female entrepreneurs.</h4>
                        <div className="Podcast__inner">
                            <div className="Podcast__left">
                                <img src={sabrina_image} alt="Sabrina Habib" />
                            </div>
                            <div className="Podcast__right">
                                <p className="quote">&ldquo;Policy makers don't make decisions based on their hearts, they make it based on their wallets. And I think if we can start framing this as an economic issue, that childcare will unleash this potential for women to work, we can completely boost the economy of nations.&rdquo;</p>
                                <p className="author">&mdash;Sabrina Habib, Founder of <a href="https://www.kidogo.co/" target="_blank" rel="noreferrer">Kidogo</a>, largest childcare network in Kenya</p>
                                
                            </div>
                        </div>
                    </div>
                </SlickSlider>
            </div>
          );
	}
}

export default Slider;
