import React from 'react';
import styles from './Callout.module.scss';

class Callout extends React.Component {
	render() {
		let variationStyle = '';

		if('graphic_style' === this.props.variation) {
			variationStyle = styles.CalloutGraphic;
		}

		return (
			<div className={styles.Callout + ' ' + variationStyle} style={{ color: this.props.color, maxWidth: this.props.maxWidth }}>
				<h2 className={styles.CalloutHeader}>{this.props.header}</h2>

				{this.props.children}
			</div>
		);
	}
}

export default Callout;
