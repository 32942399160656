import React from 'react';
import styles from './IllustrationBox.module.scss';

class IllustrationBoxes extends React.Component {
	render() {
		return (
			<div className={styles.IllustrationBoxes}>
				<div className={styles.Background} style={{backgroundImage: `url(${this.props.bgImage})`}}></div>
				<div className={styles.Contents} ref={this.scrollRef}>
					{this.props.stickyHeader && 
						<div className="StickyHeader" style={{ backgroundColor: this.props.stickyHeaderBgColor }}>
							{this.props.stickyHeader}
						</div>
					}
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default IllustrationBoxes;
