import FullWidthSection from '../components/FullWidthSection/FullWidthSection.js';

import top_image from '../images/3-Path-Forward-Closing_Illo.jpg';
import fp_logo from '../images/logos/FPA-revised-0607.svg';
import gates_logo from '../images/logos/bill-melinda-gates-foundation-logo-vector.svg';

function Section3() {
	return (
		<section className="ScrollableSection" id="section-3" data-header-text="3. The Path Forward" >
			<FullWidthSection style={{marginTop: 0, marginBottom: '1em', zIndex: 1, position: 'relative'}}>
				<img src={top_image} alt="The Path Forward" width="100%" />
			</FullWidthSection>

			<h2 className="header-h2">3. The Path Forward</h2>

			<p>The differential and outsized socioeconomic impacts of COVID-19 on women and girls are predicted to be long-lasting. Economic shocks and labor market disruptions driving women into poverty, magnification of the care crisis, weak social protection systems that exacerbate the vulnerability of the most marginalized women, a lack of gender data, and gender-blind policy responses have all contributed to worsening gender inequality globally. </p>

			<p>These systemic failures are not intractable, though. This paper has laid out a comprehensive policy agenda for governments to act swiftly and course-correct. Policymakers must: </p>
			<ul className="font-graphics">
				<li>Support universal and gender-responsive social protections and safety nets that reduce gender-based vulnerabilities throughout life, regardless of employment or migration status;</li>
				<li>Undertake job stimulus, targeted support, and multisectoral policy action to restructure labor markets that marginalize women; </li>
				<li>Rebuild economic and health systems that do not rely upon the unpaid and underpaid care work of women; </li>
				<li>Mobilize more and better resources to support gender equality nationally and abroad; and</li>
				<li>Invest in robust gender data systems and research efforts that bring visibility to people’s differential and specific barriers and needs based on their gender and other intersecting sources of inequality.</li>
			</ul>
			<p>Doing so will ensure that the path toward long-term resilience and post-pandemic recovery is grounded in gender equality and safeguards the rights and well-being of all people. </p>

			<p><i>By FP Analytics, the research and advisory division of Foreign Policy.</i></p>
			<p><i>With support from the Bill & Melinda Gates Foundation</i></p>

			<div className="row">
				<div className="col-3">
					<p>
						<img src={fp_logo} alt="Foreign Policy Logo" style={{maxWidth: "100px", marginTop: "-15px"}} />
					</p>
				</div>
				<div className="col-9">
					<p>
						<img src={gates_logo} alt="Bill and Melinda Gates Foundation Logo" width="350" />
					</p>
				</div>
			</div>

			<p><i>Illustrations by Nhung Lê for FP Analytics</i></p>

		</section>
	);
}

export default Section3;
