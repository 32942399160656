import React from 'react';
import styles from './Sidebar.module.scss';
import Scrollspy from 'react-scrollspy'

import DownArrow from '../../images/arrow-down.png';
import pdf_icon from '../../images/pdf-file.svg';
import care_icon from '../../images/care-crisis-icon.svg';
import report_icon from '../../images/report-icon.svg';
import podcast_icon from '../../images/microphone.svg';
import video_icon from '../../images/video-icon.svg';

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expand: false,
		};

		this.handleToggleExpand = this.handleToggleExpand.bind(this);
		this.handleScrollspyUpdate = this.handleScrollspyUpdate.bind(this);

		this.getClassVal = this.getClassVal.bind(this);
	}

	handleToggleExpand() {
		this.setState((s) => ({ ...s, expand: !s.expand }));
	}

	handleScrollspyUpdate(sectionEl) {
		if (!sectionEl) {
			return this.props.onUpdate('');
		}

		this.props.onUpdate(sectionEl.getAttribute('data-header-text'));
	}

	getClassVal(navElClass) {
		let displayVal = '';
		if( 'roundedicon' === navElClass ) { 
			if (window.location.pathname === '/') {
				displayVal = styles.Show;
			} else {
				displayVal = styles.Hide;
			}
	    } else if( 'reporticon' === navElClass ) {
	    	if (window.location.pathname === '/') {
				displayVal = styles.Hide;
			} else {
				displayVal = styles.ShowAlways;
			}
	    }
        return displayVal;
	}

	render() {
		const { expand } = this.state;
		
		return (
			<div className="col-2 App-sidebar">
				<div className={styles.Sidebar}>
					<Scrollspy 
						items={ ['section-0', 'section-1', 'section-2', 'section-3'] } 
						currentClassName={styles.RoundedIconActive} 
						onUpdate={this.handleScrollspyUpdate} 
						className={expand ? styles.DisplayAll : ''} 
						scrolledPastClassName={ styles.isScrolledPast }
						offset={0}
					>
						{/* blank placeholder <li> for the introduction section */}
						<li className={styles.RoundedIcon + ' ' + styles.HideAlways}></li>

						<li className={styles.RoundedIcon + ' ' + this.getClassVal("roundedicon")}>
							<a href="#section-1" onClick={this.handleToggleExpand}>
								<span className={styles.NumberHighlight}>1</span>
								<span className={styles.AdditionalText} style={{minWidth: 175}}>
									<span className={styles.AdditionalTextNumber}>1.</span>
									The Socioeconomic impacts of COVID-19
								</span>
							</a>
							<div className={styles.ToolTip}>
								<span>Explore the Report</span>
							</div>
						</li>
						<li className={styles.RoundedIcon + ' ' + this.getClassVal("roundedicon")}>
							<a href="#section-2" onClick={this.handleToggleExpand}>
								<span className={styles.NumberHighlight}>2</span>
								<span className={styles.AdditionalText} style={{minWidth: 215}}>
									<span className={styles.AdditionalTextNumber}>2.</span>
									Policy Recommendations
								</span>
							</a>
						</li>
						<li className={styles.RoundedIcon + ' ' + this.getClassVal("roundedicon")}>
							<a href="#section-3" onClick={this.handleToggleExpand}>
								<span className={styles.NumberHighlight}>3</span> 
								<span className={styles.AdditionalText} style={{minWidth: 165}}>
									<span className={styles.AdditionalTextNumber}>3.</span>
									The Path Forward
								</span>
							</a>
						</li>
						<li className={styles.ReportIcon + ' ' + this.getClassVal("reporticon")}>
							<a href="/">
								<img className={styles.FPLogo} src={report_icon} alt="Read the Report" /> 
								<span className={styles.AdditionalText} style={{minWidth: 200}}>Read the Report</span>
							</a>
						</li>
						<li className={styles.ImageIcon}>
							<a href="/Elevating-Gender-Equality-in-COVID-19-Economic-Recovery.pdf" download>
								<img className={styles.FPLogo} src={pdf_icon} alt="Download PDF" /> 
								<span className={styles.AdditionalText} style={{minWidth: 200}}>Download the full analysis</span>
							</a>
						</li>
						<li className={styles.ImageIcon + ' ' + this.getClassVal("roundedicon")}>
							<a href="/caregiving">
								<img className={styles.FPLogo} src={care_icon} alt="A Look at the Global Care Crisis" /> 
								<span className={styles.AdditionalText} style={{minWidth: 240}}>A Look at the Global Care Crisis</span>
							</a>
						</li>
						<li className={styles.ImageIcon}>
							<a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
								<img className={styles.FPLogo} src={podcast_icon} alt="Listen Podcast" /> 
								<span className={styles.AdditionalText} style={{minWidth: 170}}>Listen to the Podcast</span>
							</a>
						</li>
						<li className={styles.ImageIcon}>
							<a href="https://foreignpolicy.com/events/fp-gender-equality-worlds-economic-recovery-gates-foundation-covid-pandemic/" target="_blank" rel="noreferrer">
								<img className={styles.FPLogo} src={video_icon} alt="Watch event" /> 
								<span className={styles.AdditionalText} style={{minWidth: 170}}>Watch Event</span>
							</a>
						</li>
					</Scrollspy>
					<span className={styles.IconWrapper} onClick={this.handleToggleExpand}>
						<img src={DownArrow} alt="Down arrow" className={`${styles.Icon} ${expand ? styles.IconRotate : ''}`} />
					</span>
				</div>
			</div>

		);
	}
}

Sidebar.defaultProps = {
	onUpdate: () => null,
};

export default Sidebar;
