import React from 'react';

import ExecutiveSummary from '../../components/ExecutiveSummary/ExecutiveSummary.js';
import DownloadReport from '../../components/DownloadReport/DownloadReport.js';
import SummaryOfCareCrisis from '../../components/SummaryOfCareCrisis/SummaryOfCareCrisis.js';

import Section1 from '../../sections/Section1.js';
import Section2 from '../../sections/Section2.js';
import Section3 from '../../sections/Section3.js';
import References from '../../components/References/References.js';

class Home extends React.Component {
	render() {
		return (
			
			<div className="App-text col-8">
				<section className="ScrollableSection" id="section-0" data-header-text="">
					<p className="author-text">By FP Analytics, the independent research division of Foreign Policy Magazine</p>

					<p>The socioeconomic fallout from the COVID-19 pandemic has derailed progress toward gender equality globally. Studies from around the world reveal that women and girls are increasingly more likely to face poverty, economic insecurity, gender-based violence, and barriers to accessing critical health services. They are also disproportionately bearing the burden of increases in unpaid care and domestic labor due to a global contraction of the care sector. In the past, health crises and economic shocks have widely exacerbated gender inequities, and these setbacks have persisted largely because of recovery plans that ignore the differential needs that women face. As these gender-blind policies and interventions continue to fail women, so too do they impede greater economic recovery and growth.</p>

					<p>A sustainable, equitable, and just recovery from COVID-19 requires purposeful policy action to mitigate the worsening of structural inequalities and to address their root causes. This report synthesizes existing evidence of how women have been impacted by the pandemic, how governments have responded to date, and what is at stake if policymakers fail to enact more inclusive recovery measures. It also provides recommendations for rights-based policies, interventions, and investments underpinned by rigorous gender analysis. Finally, this report recognizes that “the <a href="https://www.unwomen.org/en/digital-library/publications/2018/2/gender-equality-in-the-2030-agenda-for-sustainable-development-2018" target="_blank" rel="noreferrer">women and girls who are furthest behind</a> often experience multiple inequalities and intersecting forms of discrimination, including based on their sex, age, class, ability, race, ethnicity, sexual orientation, gender identity, and migration status” and calls for an intersectional and nuanced approach to evidence-based policymaking that benefits everyone.</p>

					<ExecutiveSummary />

					<DownloadReport />

					<SummaryOfCareCrisis />
				</section>

				<Section1 />
				<Section2 />
				<Section3 />

				<References style={{backgroundColor: '#ebe8e3'}} />
			</div>
				
		);
	}
}

export default Home;
