import React from 'react';
import FullWidthSection from '../FullWidthSection/FullWidthSection.js';
import ContainedContent from '../FullWidthSection/ContainedContent.js';
import ExpandableSection from '../ExpandableSection/ExpandableSection.js';

import styles from './ExecutiveSummary.module.scss';

class ExecutiveSummary extends React.Component {
	constructor(props) {
		super(props);
		this.toggleBackground = this.toggleBackground.bind(this);
		this.state = {
			isSummaryOpen: false,
			backgroundColor: ''
		};
	}

	toggleBackground(e) {
		this.setState((state, props) => ({
			isSummaryOpen: !state.isSummaryOpen,
			backgroundColor: !state.isSummaryOpen ? '#ebe8e3' : ''
		}));
	}

	render() {
		return (
			<FullWidthSection className={styles.ExecutiveSummary} style={{backgroundColor: this.state.backgroundColor}}>
				<ContainedContent>
					<ExpandableSection
					  title="Read the Executive Summary"
					  titleBackground="#ebe8e3"
					  onToggle={this.toggleBackground}
					  padding="0"
					>
						<p>Studies around the world are revealing differential and disproportionate socioeconomic impacts of the COVID-19 pandemic on women arising from the compounding effects of many complex factors. To contribute to the discourse and influence key decision-making, FP Analytics has synthesized evidence of the devastating gendered effects of the pandemic and current government responses, and provides recommendations for rights-based policies, interventions, and investments underpinned by rigorous gender analysis.</p>

						<div className={styles.Section}>
							<h3 className="header-h3 no-border">
								<a href="#section-1">The Socioeconomic Impacts of COVID-19</a>
							</h3>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-1-1"><strong>Women and Work</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Women are overrepresented in low-paid and low-skilled sectors and occupations, and are more likely to work in the informal sector, bear disproportionate burdens of care work, and have less access to social protections and health entitlements associated with formal employment than men. </li>
								<li>Both women wage workers and entrepreneurs are disproportionately concentrated in social sectors—including hospitality, retail, food services, and tourism—that are among the hardest hit by the pandemic. </li>
								<li>Emerging evidence reveals disproportionately high and more permanent job and income losses for women, and slower recovery when compared to men. Women-led enterprises have been more likely to report closures compared to those led by men, and have been disproportionately affected in sales, profits, liquidity, and growth.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-1-2"><strong>The Care Crisis</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Care work is undervalued and most often unpaid or underpaid. This structural division exploits and subordinates women, particularly those already marginalized in society and more likely to experience poverty.</li>
								<li>Increases in women’s unpaid care work are due to a severe global contraction of the paid care sector. If the sector cannot rebound, it will continue to drive major job and earnings losses—including for center-based child care workers in formal and informal settings and domestic workers providing care to private households—as well as limit the ability of women with unpaid care responsibilities across all sectors to re-enter the workforce. </li>
								<li>Migrant domestic workers are particularly vulnerable to the impacts of COVID-19 because they are often informally employed and beyond the reach of labor laws, health entitlements, and social protection schemes.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-1-3"><strong>Cross-Cutting Issue: Women in the Informal Economy</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Women wage workers and entrepreneurs in the informal economy have experienced widespread losses, and their recovery is slower compared to men. As a result, many women have depleted their savings, sold assets, and taken on perhaps unsurmountable debt.</li>
								<li>Women working in the informal sector face specific and heightened experiences of vulnerability arising from the compounding effects of both their gender and the informality of their work. Without labor and social protections and adequate resources, and beyond the reach of legal recourse, women informal workers are at a higher risk of sexual- and gender-based violence and exploitation.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-1-4"><strong>Gender-blind Policy Responses</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>The vast majority of pandemic recovery policies to date—including social protection, labor market, fiscal, and economic measures—have been designed without a gender perspective. This can worsen existing economic, health, and other inequities.</li>
								<li>As national debts soar, the temptation of austerity measures threatens the welfare of women. More than 80 percent of the International Monetary Fund (IMF) loans negotiated since March 2020 lock countries into fiscal consolidation measures that could lead to cuts to critical social and health services upon which women rely, particularly those of lower socioeconomic status and in low- and middle-income countries.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-1-5"><strong>Lack of Gender Data</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Glaring gender data gaps, which have been worsened by the pandemic’s toll on data collection mechanisms globally, are long-standing and partly the result of the perception of gender data as additive, as opposed to fundamental; a lack of gender-intentional, standardized, and comparable measures; and chronic under-investment and lack of prioritization.</li>
								<li>More and better gender data are needed in a number of areas, including: health care access and usage; women’s participation and leadership; COVID-19-related data; the health and socioeconomic impacts of COVID-19 on gender bias, discrimination, mistreatment experiences, beliefs, norms, and agency; sexual- and gender-based violence; social protection coverage; unpaid care work; the enabling environment for women entrepreneurs; and women in the informal economy.</li>
							</ul>
						</div>

						<div className={styles.Section}>
							<h3 className="header-h3 no-border">
								<a href="#section-2">Policy Recommendations</a>
							</h3>
							<p className="font-graphics">To comprehensively respond to the socioeconomic impacts of COVID-19, mitigate the gender-inequitable consequences of policy action to date, and address the root causes of gender and other intersecting inequalities, FP Analytics has crafted a call to action to governments spanning five overarching priority areas:</p>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-2-1"><strong>Supporting universal and gender-responsive social protections and safety nets that reduce gender-based vulnerabilities throughout life, regardless of employment or migration status</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Urgent and long-term support should be allocated to groups that are not currently eligible under existing government schemes, disproportionately experience financial hardship and poverty, and/or already face barriers to accessing their rights.</li>
								<li>Policies and programs must be designed, communicated, and delivered in ways that account for the barriers faced by women and other vulnerable groups.</li>
								<li>Social protection systems, including publicly funded care services, maternity and child benefits, health entitlements, services for survivors of gender-based violence, and fair and livable pensions, should be universal, gender-responsive, and independent of labor market trajectories or prior contributions to social security programs.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-2-2"><strong>Undertaking job stimulus, targeted support, and multisectoral policy action to restructure labor markets that marginalize women</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Investing in care can drive economic recovery and long-term growth. Increased wages for existing care workers and additional employment in care would generate more jobs overall, more jobs for women displaced workers, and broadly increase women’s labor force participation as additional care services become available.</li>
								<li>Publicly funded technical and vocational training and job placement programs to re-skill and up-skill displaced women workers, including stimulus programs that focus on low-carbon green jobs, would contribute to creating vibrant economies grounded in social and environmental sustainability.</li>
								<li>Labor markets policies to increase protections in the informal economy and policy and legal frameworks to eliminate gender pay gaps and gender-based discrimination are more important than ever before.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-2-3"><strong>Rebuilding economic and health systems that do not rely upon the unpaid and underpaid care work of women</strong></a>
							</p>
							<ul className="font-weight-500">
								<li><div>The International Labour Organization (ILO) 5R Framework for Decent Care Work has emerged as a useful framework to advocate for a comprehensive policy agenda to address the care crisis. It calls for <em>recognizing, reducing, and redistributing</em> unpaid care work; <em>rewarding </em>paid care work, by promoting more and decent work for care workers; and guaranteeing care workers’ <em>representation</em>, social dialogue, and collective bargaining.</div></li>
								<li>Foreign Policy has expanded upon the original 5R framework and identified seven areas for government action, providing actionable recommendations and illustrative examples across: social protections (including health policies), labor policies, macroeconomic policies and government spending, migration policies, data systems, education policies, and community mobilization and participation.</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-2-4"><strong>Mobilizing more and better resources to support gender equality both nationally and abroad</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Policymakers should increase domestic resource mobilization and design sustainable financing strategies for improved gender equity in COVID-19 recovery efforts.</li>
								<li>Governments must ensure that all structural adjustments undergo rigorous intersectional gender analysis by funding gender impact assessment to understand how policies affect populations in different ways.</li>
								<li>Governments should use gender-responsive budgeting (GRB) to increase the prioritization of women and mobilize adequate resources to address their needs, with both domestic and overseas development aid (ODA).</li>
							</ul>

							<p className={styles.SubSection + ' font-graphics'}>
								<a href="#section-2-5"><strong>Investing in robust gender data systems and research efforts that bring visibility to people’s differential and specific barriers and needs based on their gender and other intersecting sources of inequality</strong></a>
							</p>
							<ul className="font-weight-500">
								<li>Policymakers use existing gender data and evidence; prioritize and generate demand for new gender data and evidence; support the development and deployment of responsible and ethical innovations; support the standardization and expansion of gender data collection, reporting, use, and sharing; and create an enabling environment by designating adequate resources to improve gender data infrastructures, capacities, and practices.</li>
							</ul>
						</div>
					</ExpandableSection>
				</ContainedContent>
			</FullWidthSection>
		);
	}
}

export default ExecutiveSummary;
