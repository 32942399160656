import React from 'react';
import styles from './FullWidthSection.module.scss';

class FullWidthSection extends React.Component {
	render() {
		return (
			<div className={styles.FullWidthSection + ' ' + this.props.className} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}

export default FullWidthSection;
