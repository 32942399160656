import React from 'react';
import FullWidthSection from '../FullWidthSection/FullWidthSection.js';
import ContainedContent from '../FullWidthSection/ContainedContent.js';
import ExpandableSection from '../ExpandableSection/ExpandableSection.js';

import styles from './DownloadReport.module.scss';

class DownloadReport extends React.Component {
	render() {
		return (
			<FullWidthSection className={styles.DownloadReport}>
				<ContainedContent>
					<p className={styles.Button}><a href="/Elevating-Gender-Equality-in-COVID-19-Economic-Recovery.pdf" download>Download the Full Report</a></p>
				</ContainedContent>
			</FullWidthSection>
		);
	}
}

export default DownloadReport;
