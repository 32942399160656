import React from 'react';
import ContainedContent from '../FullWidthSection/ContainedContent';

import styles from './Header.module.scss';

import fp_logo from '../../images/logos/FPA-horizontal-logo.svg';

class Header extends React.Component {
	render() {
		return (
			<header className={styles.Header}>
				<ContainedContent>
					<div className={styles.Sections}>
						<div className={styles.Section}>
							<div className={styles.NavLabel} key={this.props.navLabel}>{this.props.navLabel}</div>
						</div>

						<div className={styles.Section}>
							<a href="/">
								<img className={styles.FPLogo} src={fp_logo} alt="FP Analytics Logo" />
							</a>
						</div>
					</div>
				</ContainedContent>
			</header>
		);
	}
}

Header.defaultProps = {
	navLabel: '',
};

export default Header;
