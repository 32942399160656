import React from 'react';
import styles from './MidWidthSection.module.scss';

class MidWidthSection extends React.Component {
	render() {
		return (
			<div className={styles.MidWidthSection + ' ' + this.props.className} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}

export default MidWidthSection;
