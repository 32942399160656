import React from 'react';
import styles from './Hero.module.scss';

class Hero extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			heroTextOpacity: 0,
			heroTextTranslateY: 100
		};

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event) {
		let scrollTop = event.target.scrollingElement.scrollTop;
	
		this.setState({
			heroTextOpacity: Math.min(1, (scrollTop+25) / 100),
			heroTextTranslateY: Math.max(0, 100 - scrollTop)
		});
	}

	render() {
		return (
			<div className={styles.Hero} style={{backgroundImage: `url(${this.props.image})`}}>
				<div className={styles.HeroText} style={{
					opacity: `${this.state.heroTextOpacity}`,
					transform: `translateX(calc(50vw - 50%)) translateY(${this.state.heroTextTranslateY}%)`
				}}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Hero;
