import FullWidthSection from '../components/FullWidthSection/FullWidthSection.js';
import ContainedContent from '../components/FullWidthSection/ContainedContent.js';
import IllustrationBoxes from '../components/IllustrationBox/IllustrationBoxes.js';
import IllustrationBox from '../components/IllustrationBox/IllustrationBox.js';
import Callout from '../components/Callout/Callout.js';
import CalloutSecondary from '../components/Callout/CalloutSecondary.js';
import Spotlight from '../components/Spotlight/Spotlight.js';
import CaseStudies from '../components/CaseStudy/CaseStudies.js';

import illustration_image_5 from '../images/illustration/Box1-Woman&Work_03.jpg';
import illustration_image_6 from '../images/illustration/Box3a-The_Care_Crisis_01.jpg';
import gender_blind from '../images/illustration/1-4-Gender_Blind_Response_02.jpg';

import lack_gender from '../images/illustration/Box7-Gender-data.png';

import supply_shocks_hospitality from '../images/Box2d-Indian_Women_Entrepreneurs-Transparent.png';


import data_viz_1_desktop from '../images/visualizations/1.1_01_data-viz_desktop-1440.png';
import data_viz_1_mobile from '../images/visualizations/1.1_01_data-viz_mobile-320@2x.png';

import data_viz_1_1_box_1_bullet_1_desktop from '../images/visualizations/1.1_02_data-viz_desktop-1440.png';
import data_viz_1_1_box_1_bullet_1_mobile from '../images/visualizations/1.1_02_data-viz_mobile-320@2x.png';
import data_viz_1_1_box_1_bullet_2_desktop from '../images/visualizations/1.1_03_data-viz_desktop-1440.png';
import data_viz_1_1_box_1_bullet_2_mobile from '../images/visualizations/1.1_03_data-viz_mobile-320@2x.png';
import data_viz_1_1_box_1_bullet_3_desktop from '../images/visualizations/1.1_04_data-viz_desktop-1440.png';
import data_viz_1_1_box_1_bullet_3_mobile from '../images/visualizations/1.1_04_data-viz_mobile-320@2x.png';
import data_viz_1_1_box_1_bullet_4_desktop from '../images/visualizations/1.1_05_data-viz_desktop-1440@3x.png';
import data_viz_1_1_box_1_bullet_4_mobile from '../images/visualizations/1.1_05_data-viz_mobile-320@3x.png';

import data_viz_1_1_box_2_bullet_1_desktop from '../images/visualizations/1.1_06_data-viz_desktop-1440@3x.png';
import data_viz_1_1_box_2_bullet_1_mobile from '../images/visualizations/1.1_06_data-viz_mobile-320@3x.png';
import data_viz_1_1_box_2_bullet_3_desktop from '../images/visualizations/1.1_08_data-viz_desktop-1440@3x.png';
import data_viz_1_1_box_2_bullet_3_mobile from '../images/visualizations/1.1_08_data-viz_mobile-320@2x.png';


import data_viz_1_2_box_3_1_desktop from '../images/visualizations/1.2_09_data-viz_desktop-1440.png';
import data_viz_1_2_box_3_1_mobile from '../images/visualizations/1.2_09_data-viz_mobile-320@2x.png';
import data_viz_1_2_box_3_2_desktop from '../images/visualizations/1.2_10_data-viz_desktop-1440.png';
import data_viz_1_2_box_3_2_mobile from '../images/visualizations/1.2_10_data-viz_mobile-320@2x.png';

import data_viz_1_2_box_3_5_desktop from '../images/visualizations/1.2_11_data-viz_desktop-1440@3x.png';
import data_viz_1_2_box_3_5_mobile from '../images/visualizations/1.2_11_data-viz_desktop-1440@3x.png';

import data_viz_1_3_box_5_2_desktop from '../images/visualizations/1.3_12_data-viz_desktop-1440@3x.png';
import data_viz_1_3_box_5_2_mobile from '../images/visualizations/1.3_12_data-viz_mobile-320@3x.png';

import data_viz_1_3_box_5_3_desktop from '../images/visualizations/1.3_13_data-viz_desktop-1440.png';
import data_viz_1_3_box_5_3_mobile from '../images/visualizations/1.3_13_data-viz_mobile-320@2x.png';
import data_viz_1_3_box_5_4_desktop from '../images/visualizations/1.3_14_data-viz_desktop-1440.png';
import data_viz_1_3_box_5_4_mobile from '../images/visualizations/1.3_14_data-viz_mobile-320@2x.png';
import data_viz_1_4_1_desktop from '../images/visualizations/1.4_15_data-viz_desktop-1440@3x.png';
import data_viz_1_4_1_mobile from '../images/visualizations/1.4_15_data-viz_mobile-320@3x.png';
import data_viz_1_4_2_desktop from '../images/visualizations/1.4_16_data-viz_desktop-1440@3x.png';
import data_viz_1_4_2_mobile from '../images/visualizations/1.4_16_data-viz_mobile-320@3x.png';

import venn_diagram_desktop from '../images/visualizations/1.3-Fig1-Venn-diagram-desktop.png';
import venn_diagram_mobile from '../images/visualizations/1.3-Fig1-Venn-diagram-mobile.png';
import ExpandableSection from '../components/ExpandableSection/ExpandableSection.js';


import street_vendors_image from '../images/street-vendors-Problem_Informal_Industries.png';
import waste_pickers_image from '../images/waste-picker-Problem_Informal_Industries.png';
import agricultural_workers_image from '../images/ag-worker-Problem_Informal_Industries.png';

function Section1() {
	return (
		<section className="ScrollableSection" id="section-1" data-header-text="1. The Socioeconomic Impacts of COVID-19">
			<div id="section-1" />
			<h2 className="header-h2 no-border">1. The Socioeconomic Impacts of COVID-19</h2>

			<div id="section-1-1" className="anchor" />
			<h3 className="header-h3">1.1 Women and Work</h3>

			<p>As the COVID-19 pandemic devastates economies and disrupts labor markets globally, women have dropped out of the workforce at a greater rate than men. The<a href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/documents/publication/wcms_751785.pdf" target="_blank" rel="noreferrer"> International Labour Organization (ILO) reported</a> that at the global level, employment loss for women in 2020 was 5 percent, compared to 3.9 percent for men. </p>

			<p className="image text-center show-for-desktop"><img src={data_viz_1_desktop} alt="Women and Work" /></p>
			<p className="image text-center show-for-mobile"><img src={data_viz_1_mobile} alt="Women and Work" /></p>
			
			<p>These declines will likely prove long-lasting due to underlying pervasive and systemic inequities. Women face myriad, and often compounding, factors, including restrictive gender norms that curtail their autonomy and mobility, the burden of unpaid care work, occupational and sectoral segregation, and unequal access to resources, such as time, financial services, educational and skills-building opportunities, and technology.</p>

			<Callout
				color="#000"
				header="Compounding factors driving severe longer-lasting impacts on women’s employment:"
				maxWidth="100%"
				variation="graphic_style"
			>
				<ol className="font-weight-500">
					<li>Restrictive gender norms regarding autonomy and mobility</li>
					<li>The burden of unpaid care work</li>
					<li>Occupational and sectoral segregation</li>
					<li>Unequal access to resources (such as time, financial services, educational and skills-building opportunities, and technology)</li>
				</ol>
			</Callout>

			<p>To analyze the impact of the pandemic on employment, understanding the socio-structural context is critical. Workforces and value chains operate within a social context influenced by gender dynamics and hierarchies that define roles and opportunities and may create barriers to resources. For example, women are overrepresented in low-paid and low-skilled sectors and occupations, more likely to work in the informal sector, and bear disproportionate burdens of care and domestic work. As a result, they earn less, save less, and have less access to social protections and health care benefits associated with formal employment than men. Women, because of their lower status in the labor market, are typically the first to be laid off in times of crisis. When they seek to return to the workforce, they face heightened barriers such as lack of affordable and quality care services for children and other family members, such as those living with a disability and the elderly.</p>

			<FullWidthSection>
				<IllustrationBoxes 
					bgImage={illustration_image_5} 
					stickyHeader="Box 1: Impact of COVID-19 on Jobs and Earnings"
					stickyHeaderBgColor="#2A63D4"
				>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="up"
						textBgColor="#2A63D4"
						title=""
						image={data_viz_1_1_box_1_bullet_1_desktop}
						mobileImage={data_viz_1_1_box_1_bullet_1_mobile}
						text="<span class='show-for-mobile'><strong>Loss of income:</strong><a href='https://www.care-international.org/files/files/RGA_SheToldUsSo_9_18_20.pdf' target='_blank'> 55 percent of women</a> surveyed across 40 countries cited income loss as the greatest impact of COVID-19 on their lives, compared to 34 percent of men.</span>"
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="down"
						textBgColor="#2A63D4"
						title=""
						image={data_viz_1_1_box_1_bullet_2_desktop}
						mobileImage={data_viz_1_1_box_1_bullet_2_mobile}
						text="<strong>Permanent job loss:</strong> <a href='https://www.iza.org/publications/dp/13824/gender-inequality-during-the-covid-19-pandemic-income-expenditure-savings-and-job-loss' target='_blank'> A survey </a> of China, Italy, Japan, South Korea, the United Kingdom, and the United States found that women were 24 percent more likely to permanently lose their job compared to men. Women expected their labor income to fall by 50 percent more than men did and, to cope, tended to reduce current consumption and focused on increasing savings."
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="up"
						textBgColor="#2A63D4"
						title=""
						image={data_viz_1_1_box_1_bullet_3_desktop}
						mobileImage={data_viz_1_1_box_1_bullet_3_mobile}
						text="<strong>Disruption in paid work:</strong> <a href='https://actionaid.org/publications/2020/impact-covid-19-young-women' target='_blank'>A survey</a> in India, Kenya, Ghana, and South Africa found that 35 percent of young women were unable to continue with their regular paid work after the onset of the pandemic."
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="down"
						textBgColor="#2A63D4"
						title=""
						image={data_viz_1_1_box_1_bullet_4_desktop}
						mobileImage={data_viz_1_1_box_1_bullet_4_mobile}
						paddingBottom="1em"
						text="<strong>Slower recovery:</strong> <a href='https://www.iza.org/publications/dp/13815/the-covid-19-pandemic-and-gendered-division-of-paid-and-unpaid-work-evidence-from-india' target='_blank'>A study in India found that</a> though both men and women experienced a large decline in employment during the April 2020, men’s employment recovered almost fully by August 2020, while the recovery in women’s employment was roughly seven percentage points lower than their pre-pandemic starting points."
					/>
				</IllustrationBoxes>
			</FullWidthSection>

			<p>Women entrepreneurs also face systemic inequalities that can push them to the margins of the economy, increase their vulnerability, and limit their growth. Further, in times of crisis and economic downturn, businesses owned by underrepresented groups, such as women, racialized groups and ethnic minorities, people living with a disability, Indigenous people, immigrants, refugees, and sexual and gender minorities, <a href="https://www.genderandcovid-19.org/wp-content/uploads/2020/10/Gender-and-COVID-19-feminist-recovery-plans.pdf" target="_blank" rel="noreferrer">may face greater risk</a> because of lower levels of capitalization, fewer investors and greater reliance on self-financing, weaker customer and supplier networks, and less social support.   Historically, women-led enterprises employ fewer workers and generate lower average profits than businesses led by men. This holds true across several categories, including self-employed women, microenterprises with four or fewer employees, small enterprises with up to 19 employees, and medium-sized enterprises with up to 99 employees.</p>

			<p>This preexisting precarity and structural inequality that both women wage workers and entrepreneurs face is now being further compounded as women globally are <a href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/documents/publication/wcms_751785.pdf" target="_blank" rel="noreferrer">disproportionately concentrated in social sectors</a>—including hospitality, retail, food services, and tourism—which are among the hardest hit by the pandemic and least likely to return at the same rate as male-dominated sectors. This sectoral segregation—the overrepresentation of women and women-owned businesses in a particular economic sector—was <a href="https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/#section-d1e624" target="_blank" rel="noreferrer">advantageous during the 2008 financial crisis</a> because health services, educational services, and other personal service sectors proved less susceptible to economic downturns.  4,5  In pandemic conditions, however, what was once a protective factor now leaves women and women-owned businesses vulnerable to economic displacement. A growing body of research shows that women-led enterprises, including those in “feminized” social sectors, have been more likely to report closures across all world regions compared to those led by men, and are disproportionately affected in sales, profits, liquidity, and growth.</p>

			<p>COVID-19 is also exacerbating an existing gender gap in entrepreneurship in both developing and developed countries, particularly among women-owned small- and medium-sized enterprises (WoSMEs), and may unwind what progress women have made. COVID-19 disrupted access to relational and network capital, which may stifle growth for existing women-led businesses, keep new women entrepreneurs from entering in the marketplace, and entrench the erroneous perception that WoSMEs are less capable of surviving. </p>

			<div className="space-around-section">
				<div className="StickyHeader full-width" style={{ backgroundColor: '#F2A93B', color: '#000' }}>
					Box 2: Emerging Evidence of the Impact of COVID-19 on Women-Owned Enterprises (WoSMEs)
				</div>

				<Spotlight
					color="#F2A93B"
					textColor="#000"
					stacked="true"
					tightTop="true"
				>
					<p><strong>Disproportionate closure rates:</strong> <a href="https://dataforgood.fb.com/wp-content/uploads/2020/07/GlobalStateofSmallBusinessReport.pdf" target="_blank" rel="noreferrer">Among SMEs surveyed</a>, 26 percent had closed between January 2020 and May 2020. </p>
					<p className="image show-for-desktop"><img src={data_viz_1_1_box_2_bullet_1_desktop} alt="Disproportionate closure rates:" /></p>
					<p className="image show-for-mobile"><img src={data_viz_1_1_box_2_bullet_1_mobile} alt="Disproportionate closure rates:" /></p>
					<p>Women-led businesses were 7 percentage points more likely to close compared to those led by men, with great variation across countries. Russia reported the largest gender gap in closure rates (26 percentage points), followed by South Africa (24 percentage points), Malaysia (16 percentage points), and Canada (15 percentage points).</p>
				</Spotlight>
				<Spotlight
					color="#F2A93B"
					textColor="#000"
					stacked="true"
				>
					<div className="row">
						<div className="col-6 text-center">
							<p className="big-number">23%</p>
						</div>
						<div className="col-6">
							<p>As of June 2020, <strong><a href="https://dataforgood.fb.com/wp-content/uploads/2020/07/GlobalStateofSmallBusinessReport.pdf" target="_blank" rel="noreferrer">23 percent of WoSMEs</a></strong> surveyed reported spending six or more hours per day on care work compared to only 11 percent of men.</p>
						</div>
					</div>
				</Spotlight>
				<Spotlight
					color="#F2A93B"
					textColor="#000"
					stacked="true"
				>
					<p className="image"><img src={supply_shocks_hospitality} alt="Women Entrepreneurs Surveyed" style={{maxWidth: '100%', width: '80%', margin: '0 auto', display: 'block'}} /></p>
					<p><strong>In India, a <a href="https://www.epw.in/engage/article/how-did-indias-women-enterprises-fare-during-covid" target="_blank" rel="noreferrer">third of women entrepreneurs surveyed</a></strong> in four states closed their business either temporarily or permanently. Half of respondents who reported permanent closure of business also that reported they were unlikely to restart a business again.</p>
				</Spotlight>
				<Spotlight
					color="#F2A93B"
					textColor="#000"
					stacked="true"
				>
					<p><strong>Supply shocks in the hospitality industry:</strong> Women-led micro-businesses experienced larger declines in sales revenues, according to analysis of a dataset compiled from the World Bank’s Business Pulse Survey and Enterprise Survey programs comprising 37,000 businesses across 52 mostly low to middle-income countries (LMICs).</p>
					<p className="image show-for-desktop"><img src={data_viz_1_1_box_2_bullet_3_desktop} alt="Supply shocks in the hospitality industry" /></p>
					<p className="image show-for-mobile"><img src={data_viz_1_1_box_2_bullet_3_mobile} alt="Supply shocks in the hospitality industry" /></p>
					<p>Women-led businesses in the hospitality industry had a significantly higher probability of reporting supply shocks (82.3 percent among women-led businesses versus 74.1 percent for men-led businesses). Women-led businesses in countries that experienced severe COVID-19-related disruption reported having less cash available and a higher probability of falling in arrears.</p>
				</Spotlight>
			</div>

			<div id="section-1-2" />
			<h3 className="header-h3">1.2 The Care Crisis</h3>

			<p><i>For a deep dive into this cross-cutting issue and policy recommendations to address the care crisis, read more <a href="#section-2-3">here</a>.</i></p>

			<p>Women globally experience greater economic insecurity largely because of gender roles that consider caregiving “women’s work.” Such work, while essential to sustaining economic production, is undervalued and most often unpaid or underpaid. This structural division exploits and subordinates women—particularly those who are already marginalized in society and more likely to experience poverty—as well as limits women’s participation in the formal labor force.</p>

			<p>The value of unpaid care and domestic work is estimated at <a href="https://oxfamilibrary.openrepository.com/bitstream/handle/10546/620928/bp-time-to-care-inequality-200120-en.pdf?sequence=36&isAllowed=y" target="_blank" rel="noreferrer">$10.8 trillion annually</a>—three times the size of the world’s tech industry—and has historically limited women’s labor force participation. Even before COVID-19 shuttered schools worldwide, <a href="https://www.ilo.org/global/publications/books/WCMS_633135/lang--en/index.html" target="_blank" rel="noreferrer">the ILO warned</a> of an impending “care crisis” if no actions were taken.  The organization estimated in 2018 that 1.9 billion children under age 15—including 800 million children under 6—needed care globally. COVID-19 intensified that crisis to unprecedented levels. Since March 2020, <a href="https://en.unesco.org/covid19/educationresponse/globalcoalition" target="_blank" rel="noreferrer">nearly 90 percent</a> of the world’s countries have closed schools to reduce transmission of the disease, affecting 1.5 billion school-age children.</p>

			<CalloutSecondary
				color="#23575e"
				pre_header=""
				header="90%"
				text="Since March 2020, nearly 90 percent of the world’s countries have closed schools to reduce transmission of the disease, affecting 1.5 billion school-age children."
				/>

			<p>In addition to providing unpaid child care, women also act as <a href="https://doi.org/10.1186/s12961-018-0338-5" target="_blank" rel="noreferrer">unpaid family health care givers and unpaid community health workers</a>, particularly in low- and middle-income countries.</p>

			<p>Women’s disproportionate role in unpaid care work is now acting as a “<a href="https://doi.org/10.1080/13545701.2020.1867762" target="_blank" rel="noreferrer">shock absorber</a>” that bridges the gaps in services, both public and private, that are either too expensive or no longer available because of COVID-19 restrictions.  These growing burdens are driving women out of the workforce as they close their businesses or work fewer paid hours. This crisis <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">hits hardest for the most marginalized women</a>, including those working in the informal sector, those with limited access to technology, public services, and infrastructure, single mothers, essential workers, and racial and ethnic minority women.</p>

			<p>The care crisis also affects adolescent girls, who may be tasked with more domestic responsibilities, including caring for younger siblings. <a href="https://downloads.ctfassets.net/0oan5gk9rgbh/6TMYLYAcUpjhQpXLDgmdIa/3e1c12d8d827985ef2b4e815a3a6da1f/COVID19_GirlsEducation_corrected_071420.pdf" target="_blank" rel="noreferrer">The Malala Fund estimates</a> that the pandemic could force as many as 20 million secondary school-age girls in low- and middle-income countries out of school permanently.  This dynamic affects long-term health and labor market outcomes, as well as those of future generations, by <a href="https://www.savethechildren.org/us/about-us/media-and-news/2020-press-releases/covid19-half-a-million-more-girls-risk-of-child-marriage" target="_blank" rel="noreferrer">putting girls at greater risk</a> for child marriage, adolescent pregnancy, and sexual- and gender-based violence.</p>

			<FullWidthSection>
				<IllustrationBoxes 
					bgImage={illustration_image_6}
					stickyHeader="Box 3: Emerging Evidence of Increases in Unpaid Care Work Among Women and Girls"
					stickyHeaderBgColor="#ED752E"
				>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="up"
						textColor="#fff"
						textBgColor="#ED752E"
						title=""
						image={data_viz_1_2_box_3_1_desktop}
						imageAlt="Emerging Evidence of Increases in Unpaid Care Work Among Women and Girls"
						mobileImage={data_viz_1_2_box_3_1_mobile}
						text="<strong>Greater increase in care work:</strong> <a href='https://data.unwomen.org/sites/default/files/inline-files/Whose-time-to-care-brief_0.pdf' target='_blank'>UN Women’s Rapid Gender Assessment surveys</a> conducted in 38 countries from April to November 2020 found that the pandemic forced both men and women to spend more time on child care. Thirty-three percent of women increased their time spent on at least three activities related to unpaid care work compared to 26 percent of men, who already shoulder far less of the burden."
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="down"
						textColor="#fff"
						textBgColor="#ED752E"
						title=""
						image={data_viz_1_2_box_3_2_desktop}
						imageAlt="Greater increase in care work"
						mobileImage={data_viz_1_2_box_3_2_mobile}
						text="<strong>Even more child care tasks:</strong> An <a href='https://data.unwomen.org/features/covid-19-pandemic-has-increased-care-burden-how-much-0' target='_blank'>Ipsos poll conducted for UN Women</a> in 16 high- and middle-income countries in October 2020 found the average time spent by women on child care tasks increased from 26 to 31 hours per week since the onset of the pandemic compared to an increase from 20 hours to 24 hours for men."
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="up"
						textColor="#fff"
						textBgColor="#ED752E"
						title=""
						text="<span class='big-number'>60%</span><br /><strong>Girls caring for children: </strong> In East Africa, child care provider Kidogo estimates that about 60 percent of families who were previously using Kidogo centers have now shifted responsibility to adolescent girls, some as young as 8 or 9 years old.<br/><br />In Latin America, the presence of school-age children at home is associated with an increase in job losses among women but not among men."
					/>
					<IllustrationBox 
						id="illustration-1"
						slopeStyle="down"
						textColor="#fff"
						textBgColor="#ED752E"
						title=""
						paddingBottom="1em"
						image={data_viz_1_2_box_3_5_desktop}
						imageAlt="Girls caring for children"
						mobileImage={data_viz_1_2_box_3_5_mobile}
						text="<strong>Shift from work to child care:</strong> In a survey of women-owned businesses in rural India, 43 percent of respondents reported that their unpaid care work increased, and nearly 60 percent said their time spent managing their businesses decreased. Half of all permanently closed enterprises had no plans to start a new business at the time of the survey."
					/>
				</IllustrationBoxes>
			</FullWidthSection>

			<p>These increases in unpaid care work are because of a <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">severe global contraction of the paid care sector</a> as providers closed because of social distancing measures and reduced demand, in part driven by parents’ inability to pay for services amid economic hardship. If the paid care sector cannot rebound, it will continue to drive major job and earnings losses for the women-dominated industry—including for center-based child care workers in formal and informal settings and domestic workers providing child care to private households—as well as limit the ability of women with unpaid care responsibilities across all sectors to re-enter the workforce for lack of child care.</p>
			<div className="space-around-section">
				<Spotlight
					color="#DB4832"
					textColor="#fff"
					header="Box 4: Spotlight on Migrant Domestic Workers"
				>
					<p><a href="https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---travail/documents/publication/wcms_747961.pdf" target="_blank" rel="noreferrer">The ILO estimates</a> that 55 million domestic workers—nearly 75 percent globally—have lost their jobs or had their work hours significantly reduced because of COVID-19.  Migrant domestic workers—nearly 75 percent of whom are women, typically women of color—are often informally employed and <a href="https://doi/full/10.1080/13545701.2020.1849763" target="_blank" rel="noreferrer">beyond the reach of labor laws, health entitlements, and social protections</a>. Many have limited mobility as a result of pandemic movement restrictions. They also face discrimination, risk of deportation, and lack of legal rights.  <a href="https://www.tandfonline.com/doi/full/10.1080/13545701.2021.1876906" target="_blank" rel="noreferrer">Workers employed on “sponsorship visas”</a> who lose their jobs cannot access unemployment benefits or emergency response measures.  All of these uncertainties take their toll. Women migrant domestic workers suffer overall poorer mental health because of exposure to occupational hazards such as isolation, insecurity, lack of control over their time, xenophobia, and racism, and sexual- and gender-based violence, <a href="https://www.tandfonline.com/doi/full/10.1080/13552074.2021.1885213" target="_blank" rel="noreferrer">research has found.</a></p>
				</Spotlight>
			</div>
			<p>Women form <a href="https://apps.who.int/iris/bitstream/handle/10665/311314/WHO-HIS-HWF-Gender-WP1-2019.1-eng.pdf?ua=1" target="_blank" rel="noreferrer">70 percent</a> of workers in the health and social sectors combined—and contribute $3 trillion annually to global health, half in the form of unpaid care work.</p>

			<CalloutSecondary
				color="#23575e"
				pre_header=""
				header="$3T"
				text="The amount that women contribute annually to global health, half in the form of unpaid care work"
				/>

			<p>Women working in the health care sector often face <a href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(19)30648-8/fulltext#seccestitle70" target="_blank" rel="noreferrer">systemic discrimination</a> that devalues their labor, fails to recognize their work, and limits their access to career advancement, training, and education. They also face unsafe work conditions. <a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/discussion-paper-universal-health-coverage-gender-equality-and-social-protection-en.pdf?la=en&vs=5225" target="_blank" rel="noreferrer">Violence against health workers</a>, particularly on the front lines, is a growing challenge and largely remains under-recognized and unaddressed.     Redressing the underpayment and poor employment conditions of workers who provide paid care, including health care, <a href="https://doi.org/10.1016/S0140-6736(20)31418-5" target="_blank" rel="noreferrer">must be central to recovery efforts</a>.</p>

			<div id="section-1-3" />
			<h3 className="header-h3">1.3 Cross-Cutting Issue: Women in the Informal Economy</h3>

			<p>Women wage workers employed in the informal economy, both in firms and in households, have experienced widespread job losses or reduced hours at a rate similar to their counterparts in the formal economy, and their wage recovery is slower compared to men. Similarly, informal women-led enterprises have faced dramatic earnings losses which are not recovering at the same rate as those of men. This has resulted in many women depleting their savings, selling assets, and taking on significant and perhaps unsurmountable debt.</p>

			<div className="space-around-section">
				<div className="StickyHeader full-width" style={{ backgroundColor: '#52B66B' }}>
					Box 5: Emerging Evidence of Women’s Earnings Losses and Slower Recovery in the Informal Economy
				</div>
			
				<Spotlight
					color="#52B66B"
					stacked="true"
					tightTop="true"
				>
					<p>Both men and women garment enterprise owners and workers in Hohoi, Ghana, experienced decreases in monthly earnings, hourly earnings, and weekly hours, but men were rebounding more quickly than women, the UN found.</p>
				</Spotlight>
				<Spotlight
					//variation="image_right"
					image={data_viz_1_3_box_5_2_desktop}
					mobileImage={data_viz_1_3_box_5_2_mobile}
					imageAlt="Emerging Evidence of Women’s Earnings Losses and Slower Recovery in the Informal Economy"
					color="#52B66B"
					stacked="true"
					// header="Emerging Evidence of Women’s Earnings Losses and Slower Recovery in the Informal Economy"
				>
					<p><strong>Faster recovery for men:</strong> A WIEGO analysis of COVID-19’s impact on informal workers in 12 cities finds that women’s earnings in April 2020 were about 20 percent of their pre-COVID-19 levels, compared with men who were earning about 25 percent of their pre-COVID earnings. By mid-year, women had recovered only about half of their initial earnings while men had recovered about 70 percent.</p>
				</Spotlight>
				<Spotlight
					variation="image_above"
					image={data_viz_1_3_box_5_3_desktop}
					imageAlt="32 Percent Rural Men Lost Paid Work During The Peak Of The Crisis in 2020"
					mobileImage={data_viz_1_3_box_5_3_mobile}
					color="#52B66B"
					stacked="true"
				>
					<p>In India, a study found that 32 percent rural men lost paid work during the peak of the crisis in 2020 as compared to 41 percent of rural women, and 4 percent of rural men are yet to recover compared to 11 percent of rural women. The same study found no difference across rural and urban women in terms of job loss and recovery.</p>
				</Spotlight>
				<Spotlight
					variation="image_above"
					image={data_viz_1_3_box_5_4_desktop}
					mobileImage={data_viz_1_3_box_5_4_mobile}
					imageAlt="Pay dries up at women-led businesses"
					color="#52B66B"
					stacked="true"
				>
					<p><strong>Pay dries up at women-led businesses:</strong> An <a href="https://sewabharatresearch.org/wp-content/uploads/2020/10/Protection-of-Livelihoods-SEWA-Data-September-2020.pdf" target="_blank" rel="noreferrer">analysis of women microentrepreneurs in India</a> September 2020 found that 75 percent were unable to pay their employees at all for three months after the COVID-19 crisis shut down more than 79 percent of women-led enterprises. Although 10 percent pivoted their business into a new model, they faced significant challenges procuring raw materials and getting access to markets.</p>
				</Spotlight>
			</div>

			<p>Women working in the informal sector—such as street vendors, waste pickers, and agricultural laborers and farmers—face heightened vulnerability arising from the compounding effects of their gender and the informality of their work. </p>

			<p>Informal employment on its face deprives workers of labor and social protections such as paid sick and parental leave, redress for unsafe working conditions or wage discrimination, and benefits most often linked to formal work, such as retirement schemes and health insurance. While more men than women overall are employed within the informal economy, the estimated <a href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf" target="_blank" rel="noreferrer">740 million women in the informal economy</a> globally are more likely to be employed in low-skilled, precarious, and low-paying occupations, such as domestic workers and home-based workers.  In some countries, the informal economy is the greatest source of employment for women. For example, 81.8 percent of women’s employment in India is concentrated in the informal economy, <a href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf" target="_blank" rel="noreferrer">according to the ILO</a>.</p>

			<p>Women also may experience relatively lower status to men within the gendered hierarchies of these occupational groups. This gendered labor market segregation is both driven by and contributes to women’s lower access to resources such as technology, capital, markets, and transportation. Without labor and social protections and adequate resources, and beyond the reach of legal recourse, women informal workers are at a higher risk of sexual- and gender-based violence, and exploitation by employers, supervisors, other workers, suppliers, lenders, and police.</p>

			<p>These compounding sources of marginalization—which are heightened for women who experience intersecting forms of inequality, such as those who are ethnic minorities, have a lower socioeconomic status, are living with a disability, are migrants, and others—manifest in different ways across occupation groups and sectors and are being exacerbated by COVID-19. Women informal workers face school closures and increased burdens of care work, workplace and public transportation shutdowns because of social distancing requirements and lockdowns, unequal access to resources, increases in sexual- and gender-based violence, and a greater need for personal protective equipment (PPE) to reduce transmission of COVID-19. (See Figure 1 below)</p>

			<FullWidthSection style={{backgroundColor: '#ebe8e3', padding: '2em 0 4em 0'}}>
				<ContainedContent>
					<p className="font-graphics text-center"><strong>
						Figure 1:<br />
						Women Informal Workers,<br />
						Structural Inequality, and COVID-19
					</strong></p>

					<p className="image text-center show-for-desktop">
						<img src={venn_diagram_desktop} alt="Women Informal Workers" />
					</p>
					<p className="image text-center show-for-mobile">
						<img src={venn_diagram_mobile} alt="Women Informal Workers" />
					</p>

					<div className="show-for-mobile space-bottom--mobile">
						<p className="font-graphics text-center"><strong>Compounding Factors</strong></p>

						<ExpandableSection title="Impacts of COVID-19">
							<ul>
								<li>Supply chain interruptions</li>
								<li>Social distancing and lockdown impact on workspaces</li>
								<li>Increases in sexual-based gender violence (SGBV)</li>
								<li>Shutdown of public transportation</li>
								<li>Increased need for PPE and Water, Sanitation, and Hygiene (WASH)</li>
								<li>School closures and increased care work</li>
							</ul>
						</ExpandableSection>
						<ExpandableSection title="Examples of Individual Characteristics">
							<ul>
								<li>Gender</li>
								<li>Socioeconomic Status (SES)</li>
								<li>(Dis)ability</li>
								<li>Marital/maternal status</li>
								<li>Migration status</li>
								<li>Urban/rural</li>
								<li>Caste</li>
							</ul>
						</ExpandableSection>
					</div>

					<p className="font-graphics text-center"><strong>Three Case Studies from India</strong></p>
					<p className="font-graphics text-center instructional-subhead">Click to explore intersecting effects of informal work on female workers in India</p>

					<CaseStudies
						caseStudies={[
							{
								title: "Waste Pickers",
								image: waste_pickers_image,
								content: <>
									<p>Definition of waste picker: a person who informally collects and recovers reusable and recyclable solid waste for sale to recyclers directly or through intermediaries to earn their livelihood.</p>

									<p><span className="bullet pink"></span><b>Labor market segregation</b></p>
									<ul>
										<li>In some parts of India, the vast majority of waste pickers are women (90 percent in Pune), most often illiterate and from the Dalit (or “untouchable”) caste.</li>
										<li>Women waste pickers are more likely to collect lower-value materials, enforced through threats of violence from male counterparts, and are often paid less for the same work and number of hours.</li>
										<li>In Delhi, for example, most women either segregate waste as wage workers in “godowns,” or warehouses, or they segregate waste brought home by their husbands as unpaid labor. This in part explains why, in a WIEGO study, 50 percent of women have been able to resume work compared to 86 percent of men. Men are earning nearly 70 percent of their daily average pre-lockdown income and women are earning only about a quarter of their pre-lockdown income.</li>
									</ul>

									<p><span className="bullet brown"></span><b>Lack of labor and social protections</b></p>
									<ul>
										<li>Many women waste pickers lack access to protective equipment, health entitlements, and other labor and social protections.</li>
									</ul>

									<p><span className="bullet blue"></span><b>Unequal access to resources</b></p>
									<ul>
										<li>Women tend to have less access to more valuable materials and less agency in the selling process.</li>
										<li>Lack of access to water and sanitation while working particularly endangers menstrual hygiene and, now, the ability to reduce transmission of COVID-19.</li>
									</ul>

									<p><span className="bullet orange"></span><b>Risk of sexual- and gender-based violence (SGBV) and exploitation</b></p>
									<ul>
										<li>Dealers and contractors that women depend upon may use exploitative practices, such as drastic reduction in prices of waste material.</li>
										<li>Women are exposed to SGBV at waste picking sites, including threats of physical confrontation from male counterparts on dumpsites.</li>
									</ul>
								</>
							},
							{
								title: "Street Vendors",
								image: street_vendors_image,
								content: <>
									<p><span className="bullet pink"></span><b>Labor market segregation</b></p>
									<ul>
										<li>Trading on the streets has minimal skill and capital requirements, an option that many women who lack opportunities for formal employment or other income-generating activities turn to.</li>
										<li>Women street vendors are likely to sell low-value products because of lower access to capital and greater fears of theft and violence.</li>
									</ul>

									<p><span className="bullet brown"></span><b>Lack of labor and social protections</b></p>
									<ul>
										<li>Women own fewer ration cards or other government documents compared to male counterparts to benefit from government relief programs, including those implemented in response to COVID-19.</li>
									</ul>

									<p><span className="bullet blue"></span><b>Unequal access to resources</b></p>
									<ul>
										<li>Women have lower access to capital to source quality materials and products, and to markets and public transportation – now further hindered by COVID-19 social distancing requirements and interruptions of public services.</li>
									</ul>

									<p><span className="bullet orange"></span><b>Risk of sexual- and gender-based violence (SGBV) and exploitation</b></p>
									<ul>
										<li>Women experience threats from wholesalers or money lenders, threats of eviction, theft-related violence, and physical and sexual violence by police in public spaces such as markets.</li>
									</ul>
								</>
							},
							{
								title: "Agricultural Workers",
								image: agricultural_workers_image,
								content: <>
									<p><span className="bullet pink"></span><b>Labor market segregation</b></p>
									<ul>
										<li>60.1 percent of women in the informal economy work in agriculture, compared to 45 percent of informally employed men.</li>
										<li>Women agricultural laborers often perform labor-intensive and poorly paid activities. When agriculture is mechanized, men often take over, displacing women workers.</li>
										<li>Women agricultural laborers have lost work by 15 more percentage points compared to men because of COVID-19.</li>
									</ul>

									<p><span className="bullet brown"></span><b>Lack of labor and social protections</b></p>
									<ul>
										<li>Women farmers are under-registered and undercounted as workers and therefore have less access to government schemes and entitlements designated for the agriculture sector.</li>
										<li>In a SEWA Federation study, 86 percent of women in the agriculture sector face significant debt burdens because of COVID-19-related post-harvest losses, which is compounded by less access to loans and pandemic relief measures.</li>
									</ul>

									<p><span className="bullet blue"></span><b>Unequal access to resources</b></p>
									<ul>
										<li>Women have less access to, control over, and ownership of land in India—owning less than 10.34 percent of land, operating 12.8 percent of holdings.</li>
									</ul>

									<p><span className="bullet orange"></span><b>Risk of sexual- and gender-based violence (SGBV) and exploitation</b></p>
									<ul>
										<li>Amid COVID-19 lockdowns, women farmers face police harassment and violence as they try to transport their produce across state borders or between towns.</li>
										<li>Money-lenders humiliate, insult, and often physically assault women smallholder farmers who are unable to repay loans on time.</li>
									</ul>
								</>
							}
						]}
					/>
				</ContainedContent>
			</FullWidthSection>

			<div id="section-1-4" />

			<img className="space-around-section--smaller" src={gender_blind} alt="Gender-Blind Policy Responses" width="100%" />
			<h3 className="header-h3">1.4 Gender-Blind Policy Responses</h3>

			<p>Policy responses that are <a href="https://www.cgdev.org/sites/default/files/gender-social-protection-during-covid.pdf" target="_blank" rel="noreferrer">need-blind, ad hoc, and gender-blind</a>—ignoring the unique needs and constraints of people of different genders—may worsen gender inequities while also missing opportunities for broader economic growth and resilience-building. </p>

			<p>The same systemic inequities that keep women from markets, capital, and opportunity may also impede access to generalized support. <a href="https://www.ilo.org/wcmsp5/groups/public/@dgreports/@dcomm/documents/briefingnote/wcms_767028.pdf" target="_blank" rel="noreferrer">The ILO reports</a> that women in the majority of countries it surveyed have experienced greater losses in post-support labor income (i.e., all income linked to work, including income support). This indicates that job retention programs that temporarily subsidize the income of furloughed workers have been less effective in protecting women.  In total, 214 countries and territories have adopted 1,700 social protection and labor market measures in response to COVID-19, but <a href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html" target="_blank" rel="noreferrer">only 23 percent</a> target women’s economic security or address unpaid care. </p> 

			<Callout
				color="#ED752E"
			>
				<p style={{ color: '#000', fontSize: '1.55em', lineHeight: '1.25em' }}>In total, 214 countries and territories have adopted 1,700 social protection and labor market measures in response to COVID-19...</p> 
				<p className="show-for-desktop"><img src={data_viz_1_4_1_desktop} alt="Social Protection And Labor Market Measures" /></p>
				<p className="show-for-mobile"><img src={data_viz_1_4_1_mobile} alt="Social Protection And Labor Market Measures" /></p>
				<p style={{ color: '#000', fontSize: '2em', lineHeight: '1.15em' }}>...but only <span style={{ color: '#ED752E' }}>23 percent</span> target women’s economic security or address unpaid care.</p>
			</Callout>

			<p>Even policies and programs intended for women may fail if they are not designed, implemented, and communicated in ways that actually reach them. For example, India’s Pradhan Mantri Jan Dhan Yojana (PMJDY) program aimed to reach a 200 million women, but it missed an <a href="https://egc.yale.edu/reaching-indias-poorest-women-covid-19-relief" target="_blank" rel="noreferrer">estimated 176 million low-income women</a> who qualified for the benefit but did not have PMJDY accounts.</p> 

			<p>Similarly, an Indian government-mandated loan extension program intended for women’s self-help groups that engage in collective savings and intragroup lending reached less than 20 percent of the households that participate in the women’s group, and another 20 percent were unaware of the program’s existence, according to survey data from six Indian states.</p>

			<p>Some well-intentioned policies may have unintended harmful consequences. In March 2020, the UK government <a href="https://www.bloomberg.com/news/articles/2020-04-03/britain-s-gender-pay-reporting-is-paused-because-of-pandemic" target="_blank" rel="noreferrer">suspended a regulation</a> that required companies of 250 employees or more to report on their gender pay gap for the year 2019/2020 to provide a reprieve during the pandemic. Women’s rights advocates regarded the regulation as critical to achieving pay equality, and this move to relax accountability measures may further delay progress toward that goal.</p>

			<p>Some policy measures do reach women but are insufficient to address the scale of the problem. For example, while some governments have enacted laudable emergency measures to address increases in gender-based violence, <a href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html" target="_blank" rel="noreferrer">only about a third of countries</a> with available data have taken deliberate steps to integrate protections for women and girls into long-term COVID-19 response and recovery planning.</p>

			<p>Indeed, most pandemic recovery measures fail to account for women at all. While 132 countries and territories have adopted 580 fiscal and economic recovery measures to date, <a href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html" target="_blank" rel="noreferrer">just 12 percent</a> focus on strengthening women’s economic security by channeling resources to female-dominated sectors. </p>

			<Callout
				color="#ED752E"
			>
				<p style={{ color: '#000', fontSize: '1.55em', lineHeight: '1.25em' }}>While 132 countries and territories have adopted 580 fiscal and economic recovery measures to date...</p>
				<p className="show-for-desktop"><img src={data_viz_1_4_2_desktop} alt="Fiscal And Economic Recovery Measures" /></p>
				<p className="show-for-mobile"><img src={data_viz_1_4_2_mobile} alt="Fiscal And Economic Recovery Measures" /></p>
				<p style={{ color: '#000', fontSize: '2em', lineHeight: '1.15em' }}>...just <span style={{ color: '#ED752E' }}>12 percent</span> focus on strengthening women’s economic security by channeling resources to female-dominated sectors.</p>
			</Callout>

			<p>Because of the gendered nature of the economic shock to labor markets around the world, favoring male-dominated sectors and men’s jobs in recovery efforts—unintentional as it may be—will only further exacerbate sectoral and occupational segregation and fail to address the needs of millions of women who lost, and continue to lose, jobs and earnings in hard-hit feminized sectors.</p>  

			<p>Further, as national debts soar, the temptation of fiscal consolidation threatens the welfare of women.  History shows that austerity measures often lead to cuts to social services, including those for child care, domestic violence, and maternal, sexual, reproductive, and mental health—all of which <a href="https://d3n8a8pro7vhmx.cloudfront.net/eurodad/pages/533/attachments/original/1590692297/Out_of_service.pdf?1590692297" target="_blank" rel="noreferrer">disproportionately affect women</a>, particularly those of lower socioeconomic status and in low- and middle-income countries. Despite these well-documented and devastating impacts on gender equality and, in turn, long-term economic stability and growth, <a href="https://oxfam.medium.com/the-imf-must-immediately-stop-promoting-austerity-around-the-world-49a8d7ba7152" target="_blank" rel="noreferrer">more than 80 percent</a> of the International Monetary Fund (IMF) loans negotiated since March 2020 lock countries into fiscal consolidation measures to reduce deficits after the pandemic. </p>

			<CalloutSecondary
				color="#23575e"
				pre_header="More than"
				header="80%"
				text="of the IMF loans negotiated since March 2020 lock countries into fiscal consolidation measures to reduce deficits after the pandemic."
				/>

			<p>These efforts “could result in deep cuts to public health care systems and pension schemes, wage freezes and cuts for public sector workers such as doctors, nurses, and teachers, and unemployment benefits, like sick pay.”</p>

			<p>Changing this trajectory is both an urgent and long-term need. Pandemic recovery policies designed without a gender perspective can worsen existing economic, health, and other inequities, particularly for women and gender minorities who face intersecting forms of marginalization based on, for example, age, ethnicity, ability, immigration status, sexual orientation, and other factors. Further, recovery measures will be fundamentally limited in their reach and impact unless they also eliminate the <a href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/justice-for-women-amidst-covid-19.html" target="_blank" rel="noreferrer">discriminatory legal and structural barriers</a> that marginalize women socially and economically. For example, as we have learned from <a href="https://blogs.worldbank.org/sustainablecities/how-covid-19-puts-womens-housing-land-and-property-rights-risk" target="_blank" rel="noreferrer">the HIV/AIDS crisis</a> in sub-Saharan Africa, women may be unable to claim jointly held assets and property when a spouse dies, including land farmed for food. If the legal scenario remains the same amid the pandemic, women who lose a spouse to COVID-19 could lose both home and livelihood. As of the end of April 2020—still early in the pandemic—<a href="https://uploads-ssl.webflow.com/5fce889a3c0f6e35f56692ce/5fce889a3c0f6e0e0f669306_COVID-and-Widowhood-MAY-2020.pdf" target="_blank" rel="noreferrer">about 114,000 women</a> had been widowed as a result of COVID-19.</p>

			<div id="section-1-5" />

			<img className="space-around-section--smaller" src={lack_gender} alt="Lack of Gender Data" width="100%" />
			<h3 className="header-h3">1.5 Lack of Gender Data</h3>

			<ExpandableSection title="Defining Gender Data">
				<p className="font-graphics">Gender data, also known as gender statistics, <a href="https://unstats.un.org/unsd/demographic-social/Standards-and-Methods/files/Handbooks/gender/Integrating-a-Gender-Perspective-into-Statistics-E.pdf" target="_blank" rel="noreferrer">is defined</a> by the sum of these characteristics:</p>
				<ul>
					<li>It is collected and presented most often as male/female as a primary and overall classification.</li>
					<li>It reflects gender issues.</li>
					<li>It is based on concepts and definitions that adequately reflect the diversity of women, men, and gender minorities*, and capture all aspects of their lives.</li>
					<li>It is developed through collection methods that consider stereotypes and social and cultural factors that may induce gender bias in the data.</li>
				</ul>
				<p className="font-graphics">*Gender minorities are individuals who do not identify within the gender binary of male/female or whose gender identity differs from their sex at birth.</p>
			</ExpandableSection>

			<p>While the limited data available already paints a stark picture, actual impacts are most certainly far worse. Glaring gender data and evidence must be filled in order to identify and rigorously examine the gendered socioeconomic and health impacts of COVID-19, develop and implement gender-responsive policies, and measure and monitor progress toward post-pandemic recovery and achieving the Sustainable Development Goals. <a href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf" target="_blank" rel="noreferrer">These gaps</a>, worsened by the pandemic’s toll on data collection mechanisms globally, are long-standing and partly because of:</p>

			<ul className="font-weight-500">
				<li><div><b>The perception of gender data as additive, rather than fundamental:</b> Gender data is often considered a special interest topic, despite growing recognition from across the UN System, National Statistical Offices (NSOs), and other global and local actors that gender should be mainstreamed at every stage of the knowledge generation process—from planning and conceptualization to data collection, analysis, dissemination, and use.</div></li>
				<li><div><b>Inconsistent nomenclature and accounting methods:</b> There is a lack of gender-intentional, standardized, and comparable measures, as well as best-practice guidelines, for collecting gender data. Definitional, methodological, technical, and capacity challenges plague the landscape.</div></li>
				<li><div><b>Chronic under-investment and lack of prioritization:</b> Core gender data systems have been underfunded by an average of <a href="https://data2x.org/wp-content/uploads/2021/05/State-of-Gender-Data-Financing-2021_FINAL.pdf" target="_blank" rel="noreferrer">$448 million a year</a> from 2015 to 2020.</div></li>
			</ul>  

			<CalloutSecondary
				color="#23575e"
				pre_header=""
				header="$448M"
				text="Core gender data systems have been underfunded by an average of $448 million a year from 2015 to 2020."
				/>

			<p>Developing intersectional gender data that links gender to other indicators of social stratification and inequality,  such as ethnicity, socioeconomic status, ability, and sexual orientation, is critical to ensure that the women and girls most at risk are seen.</p> 

			<p>More and better gender data are needed in a number of issue areas, such as health care access and use, and women’s participation and leadership. Others include: </p>

			<ul className="font-weight-500">
				<li><div><b>COVID-19-related data:</b> The most basic epidemiological data on COVID-19—cases and deaths—are not comprehensively and consistently sex-disaggregated, and the availability of sex-disaggregated data appears to be worsening. In the first five weeks of 2021, <a href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf" target="_blank" rel="noreferrer">only 39 percent of countries</a> reported sex-disaggregated COVID-19 case and mortality data. Data should be collected on morbidity, mortality, and vaccine administration, and adverse effects by sex and other key socio-demographic characteristics.</div></li> 
				<li><div><b>The health and socioeconomic impacts of COVID-19 on gender bias, discrimination, mistreatment experiences, beliefs, norms, and agency.</b> This <a href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf" target="_blank" rel="noreferrer">may include indicators</a> on sexual and reproductive health, unpaid care work, mental health, and food insecurity.</div></li> 
				<li><div><b>Sexual- and gender-based violence:</b> Currently, <a href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html" target="_blank" rel="noreferrer">less than one-fifth of countries</a> report measures to collect and analyze data to inform policies to counter violence against women and girls in the context of COVID-19.</div></li> 
				<li><div><b>Social protection coverage:</b> Data on gender differences in ownership of personal identity cards are missing for <a href="https://data2x.org/wp-content/uploads/2020/11/COVID-19-Vulnerability-Paper_FINAL-2.pdf" target="_blank" rel="noreferrer">more than a third of countries, and less than a quarter of low- and middle-income countries</a> report data on mobile phone ownership by women.</div></li> 
				<li><div><b>Unpaid care work:</b> Since 2010, <a href="https://unstats.un.org/sdgs/metadata/files/Metadata-05-04-01.pdf" target="_blank" rel="noreferrer">just 65 countries</a> have collected time-use data in line with Sustainable Development Goal (SDG) indicator 5.4.1 on unpaid care and domestic work.</div></li> 
				<li><div><b>The enabling environment for women entrepreneurs:</b>  This includes the use of public transport, market access, and access to and use of digital technologies and financial services.</div></li>
			</ul>
			<div className="space-around-section">
				<Spotlight
					variation="small_padding"
					color="#c56328"
					header="Box 7: Spotlight on Gender Data in the Informal Economy"
				>
					<p>Just <a href="https://data2x.org/wp-content/uploads/2020/11/COVID-19-Vulnerability-Paper_FINAL-2.pdf" target="_blank" rel="noreferrer">41 percent of LMICs</a> report data on informal jobs disaggregated by sex, let alone other intersecting social indicators.  Capturing data on women’s work in the informal economy across all categories of employment is critical to understanding the needs of all women workers in LMICs. Official statistical systems have particularly poor data on home-based and domestic workers, and even less data exists on contributing family workers, who represent about a third of informally employed women.  A review of research on the gendered impacts of COVID-19 on the informal economy found no studies on contributing family workers.</p>
				</Spotlight>
			</div>
		</section>
	);
}

export default Section1;