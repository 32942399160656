import React from 'react';
import styles from './CalloutSecondary.module.scss';

class CalloutSecondary extends React.Component {
	render() {
		return (
			<div className={styles.Callout} style={{ color: this.props.color }}>
				<sup className={styles.CalloutPreHeader}>{this.props.pre_header}</sup>
				<h2 className={styles.CalloutHeader} style={{
					fontSize: this.props.headerSize
				}}>
					{this.props.header}
				</h2>
				<p className={styles.CalloutText}>{this.props.text}</p>
			</div>
		);
	}
}

export default CalloutSecondary;
