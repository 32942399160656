import React from 'react';
import CaseStudy from './CaseStudy.js';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import styles from './CaseStudy.module.scss';

class CaseStudies extends React.Component {
	constructor(props) {
		super(props);
		this.toggleContent = this.toggleContent.bind(this);
		this.state = {
			currentContent: ''
		};
		this.myRef = React.createRef();
	}

	toggleContent(content) {
		if(content !== this.state.currentContent) {
			this.setState((state, props) => ({
				currentContent: content
			}));
		} else {
			this.setState((state, props) => ({
				currentContent: ''
			}));
		}

		return true;
	}

	navigateToTop() {
		this.myRef.current.scrollIntoView();
	}

	render() {
		return (
			<div className={styles.CaseStudies} ref={this.myRef}>
				<div className="row">
					{this.props.caseStudies.map((caseStudy, index) => 
						<div key={index} className="col-4">
							<CaseStudy
								active={this.state.currentContent === caseStudy.content}
								title={caseStudy.title}
								image={caseStudy.image}
								content={caseStudy.content}
								onToggle={this.toggleContent}
							/>
						</div>
					)}
				</div>

				{/* Shared content block for all case studies (desktop view only) */}
				{this.state.currentContent && <div className={styles.Content + ' show-for-desktop'}>
					{this.state.currentContent}
					<HighlightOffIcon className={styles.Icon} onClick={() => this.toggleContent('') && this.navigateToTop()} />
				</div>}
			</div>
		);
	}
}

export default CaseStudies;
