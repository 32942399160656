import Callout from '../components/Callout/Callout.js';
import CalloutSecondary from '../components/Callout/CalloutSecondary.js';
import Spotlight from '../components/Spotlight/Spotlight.js';
import FullWidthSection from '../components/FullWidthSection/FullWidthSection.js';
import ContainedContent from '../components/FullWidthSection/ContainedContent.js';
import ExpandableSection from '../components/ExpandableSection/ExpandableSection.js';
import Slider from '../components/SlickSlider/SlickSlider.js';

import box_8_image_desktop from '../images/Box8-Support_Collective_Empowerment.jpg';
import informal_economy_2_2 from '../images/2.2-Policy_Rec_Informal_Industries.png';
import data_viz_2_2_box_9_desktop from '../images/visualizations/2.2_17_data-viz_desktop-1440.png';
import data_viz_2_2_box_9_mobile from '../images/visualizations/2.2_17_data-viz_mobile-320@2x.png';
import data_viz_2_3_5R_desktop from '../images/visualizations/2.3_5r-rubric_desktop-1440.png';
import data_viz_2_3_5R_mobile from '../images/visualizations/2.3_5r-rubric_mobile-320@2x.png';
import data_viz_2_5_box_10_desktop from '../images/visualizations/2.5_gender-data-system-text_desktop-1440@3x.png';
import data_viz_2_5_box_10_mobile from '../images/visualizations/2.5_gender-data-system_mobile-320@3x.png';

function Section2() {
	return (
		<section className="ScrollableSection" id="section-2" data-header-text="2. Policy Recommendations">
			<div id="section-2" />
			<h2 className="header-h2 no-border">2. Policy Recommendations</h2>

			<div id="section-2-1" className="anchor" />
			<h3 className="header-h3">2.1 Universal and Gender-Responsive Social Protections and Safety Nets</h3>

			<p>Social protection systems and safety nets play critical roles in minimizing exposure to risks, staving off insecurity, and facilitating recovery from shocks. Urgent and long-term support should be allocated to groups that are not eligible under existing government programs, disproportionately experience financial hardship and poverty, and/or already face barriers to accessing their rights to health, safety, independence, and education. <a href="https://humanservices.hawaii.gov/wp-content/uploads/2020/04/4.13.20-Final-Cover-D2-Feminist-Economic-Recovery-D1.pdf" target="_blank" rel="noreferrer">Among these groups</a> are migrant women, survivors of sex trafficking, domestic workers, elderly women, and women living with a disability. <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Portugal</a>, for example, has granted all migrants and asylum-seekers, including migrant domestic workers, temporary access to citizenship rights during COVID-19. To ensure that no one is left behind, governments must ensure that migrant workers, who are key contributors to both the economies in which they work and their home countries, have access to social protection measures and safety nets as COVID-19 devastates their livelihoods and well-being. </p>

			<Callout
				color="#0c3482"
				header=""
			>
				<p style={{ fontSize: `2em` }}>In Kenya, women who received subsidized child care were more likely to get paid work and earn more.</p>
			</Callout>

			<p>Well-designed emergency cash transfers effectively counter increasing poverty and food insecurity among women, including by mitigating the effects of the care crisis. <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">In Argentina</a>, for example, Ingreso Familiar de Emergencia, a new cash transfer program, is expected to reach 3.6 million families supported by informal, self-employed, and domestic workers affected by the COVID-19 pandemic. Other forms of social assistance to improve access to care have also succeeded. In Kenya, women who received subsidized child care were more likely to get paid work and earn more.</p>

			<p><a href="https://g2p-network.org/evidence-of-digital-financial-services-impacting-womens-economic-empowerment/" target="_blank" rel="noreferrer">Digitized cash transfers</a> have emerged as a popular policy solution to expand coverage of social protection measures while enhancing women’s privacy, control, convenience, and safe access. In Uganda, for example, informal women entrepreneurs who had microfinance loan disbursements deposited directly into their mobile money accounts could better control how the loan was used and had higher profits and levels of business capital.</p>

				<p>
				In some contexts, though, rapidly scaling up digital payments <a href="https://data2x.org/wp-content/uploads/2020/11/COVID-19-Vulnerability-Paper_FINAL-2.pdf" target="_blank" rel="noreferrer">could worsen gender inequities</a>, particularly in countries with wide gender gaps in access to mobile money and bank accounts and where significant proportions of the population lack identification cards. Requirements for legal ID to access public benefits can be especially damaging to marginalized and underserved groups including women, migrants, stateless people, and gender minorities. In low-income countries, <a href="http://documents1.worldbank.org/curated/en/378931596643390083/pdf/Digital-Cash-Transfers-in-Times-of-COVID-19-Opportunities-and-Considerations-for-Womens-Inclusion-and-Empowerment.pdf" target="_blank" rel="noreferrer">more than 44 percent of women</a> lack an ID card compared with 28 percent of men. This is attributable to several factors, including discriminatory laws such as male guardianship requirements.
				</p>

				<CalloutSecondary
				color="#23575e"
				pre_header=""
				header="44%"
				text="In low-income countries, more than 44 percent of women lack an ID card compared with 28 percent of men"
			/>

			<p>To be effective, policies and programs must be <a href="https://www.cgdev.org/sites/default/files/gender-social-protection-during-covid.pdf" target="_blank" rel="noreferrer">designed, communicated, and delivered</a> in ways that account for the compounding barriers faced by women and other vulnerable groups, including mobility constraints, low literacy or illiteracy, and less access to mobile technology, financial services, and IDs. In humanitarian settings, for example, <a href="http://documents1.worldbank.org/curated/en/378931596643390083/pdf/Digital-Cash-Transfers-in-Times-of-COVID-19-Opportunities-and-Considerations-for-Womens-Inclusion-and-Empowerment.pdf" target="_blank" rel="noreferrer">best practice guidelines</a> encourage programs to use new delivery methods to eliminate the barriers women face, such as provision of mobile phones, coupled with appropriate messaging and training.</p>
			<p>Despite their importance, cash transfers and other emergency assistance measures <a href="https://www.cgdev.org/publication/gendered-dimensions-social-protection-covid-19-context" target="_blank" rel="noreferrer">are largely temporary</a> and, as they expire, will not meet the long-term needs of women and their families as the socioeconomic impacts of the pandemic continue to ripple through society, particularly in low- and middle-income countries.   In addition to extending these programs, investments should be made to strengthen and reorient social protection systems to move beyond providing immediate and largely gender-blind pandemic relief measures to supporting wide-scale reforms for a just, inclusive, and gender-equitable long-term recovery. If these structural changes are neglected, governments risk reverting back to the status quo and perpetuating economic, social, and health systems that do not work for all people.</p>

			<Callout
				color="#0c3482"
			>
				<p style={{ fontSize: `2em` }}>Investments should be made to strengthen and reorient social protection systems to move beyond providing immediate and largely gender-blind pandemic relief measures to supporting wide-scale reforms for a just, inclusive, and gender-equitable long-term recovery.</p>
			</Callout>

			<p>
			To ensure greater coverage of women, social protection systems, including publicly funded care services, maternity and child benefits, health entitlements, services for survivors of gender-based violence, and fair and livable pensions, should be universal, gender-responsive and independent of labor market trajectories or prior contributions to social security programs.</p>
			<div className="space-around-section">
			<Spotlight
				color="#2A63D4"
				variation="header_image"
				image={box_8_image_desktop}
				mobileImage={box_8_image_desktop}
				imageAlt="Supporting Women’s Empowerment Collectives to Facilitate Social Protection"
				header="Box 8: Supporting Women’s Empowerment Collectives to Facilitate Social Protection"
				fadeToggleAfter="400px"
			>
				<p>COVID-19 has highlighted the critical role women play in safeguarding their communities through last mile service and program delivery, and by facilitating social and economic development at the community level. Women’s empowerment collectives have historically been a force for change and a source of resilience in their communities, and continue to be.</p>
				<p>In sub-Saharan Africa, households with a member in a women’s savings group, in which members pool savings, lend their savings to one another with interest, and share profits, are less likely to experience food insecurity and more likely to have savings during the pandemic. Savings groups also help distribute PPE, build hand-washing stations, create community action plans to prevent and raise awareness about COVID-19, and help to respond to gender-based violence. They also contribute to the dissemination of information about social distancing and proper hygiene,  and the increased risk of child marriage, child labor, schooling disruption, and violence against children during COVID-19. <a href="https://care.org/wp-content/uploads/2020/11/Coping-With-COVID_Nigeria.pdf" target="_blank" rel="noreferrer">A study in Nigeria</a> found that 42 percent of savings groups used their social funds to support other members, 15 percent used social funds to purchase hygiene supplies, and 69 percent reported volunteering to help others or participating in groups that protect against COVID-19. </p>
				<p>Similarly, <a href="https://iwwage.org/wp-content/uploads/2020/12/Swayam-report.pdf" target="_blank" rel="noreferrer">in India</a>, women’s self-help groups (SHGs) offer financial social protection to their members. Also, as of December 2020, SHGs had set up and were managing more than 100,000 community kitchens across the country. Members also provide doorstep delivery of dry rations and essentials, such as sanitary napkins, for women-headed households, disabled persons, elderly, and widows. They also support their communities by sharing information on COVID-19 through WhatsApp, wall writings, and art forms called rangolis, or by placing a microphone on top of a community vehicle provided by local government agencies.   </p>
				<p>Women’s collectives are not impervious to the impacts of the pandemic, though, and require both urgent and structured long-term support to ensure their sustainability. For example, SHGs in India faced significantly lower mobilization of monthly savings, but those that received fund disbursements from the government experienced lower reductions in savings compared to others. <a href="https://mangotree.org/files/galleries/CRS_Covid-19_Survey-Report_FINAL.pdf" target="_blank" rel="noreferrer">A study of savings groups</a> in 11 countries in sub-Saharan Africa indicated that 75 percent of groups experienced decreased savings. </p>
				<p>In the short term, governments can target women’s collectives with cash, voucher assistance, and food aid, as well as create a dedicated public fund, to help member households mitigate the effects of the pandemic, protect member assets, and recapitalize the groups, which have been vital to pandemic response and recovery efforts. In the long term, integrating these groups into social protection programs would have wider impacts by scaling up access to and use of entitlements. Also, delivery of social safety nets and other services through the groups could provide opportunities to solidify women’s leadership in their communities and build social capital.  </p>
			</Spotlight>
			</div>

			<div id="section-2-2" />
			<h3 className="header-h3 tight-top">2.2 Gender-Responsive Job Stimulus, Targeted Support, and Labor Policies</h3>

			<p><a href="https://www.tandfonline.com/doi/full/10.1080/13545701.2020.1867762" target="_blank" rel="noreferrer">Investing in care</a>, not only as a public good but also a critical sector of the economy, can drive economic recovery and long-term growth. With past crises and shocks that have disproportionately affected men in the labor force, job stimulus programs focused on the construction sector and/or other industries that predominantly employ men. <a href="https://www.tandfonline.com/doi/full/10.1080/13545701.2020.1845390?src=recsys" target="_blank" rel="noreferrer">New research shows</a> increasing investments in care work in a COVID-19 recovery plan would deliver greater and more sustainable stimulus. Increased wages for existing care workers and added employment in care at those increased wages would generate more jobs overall, more jobs for women displaced workers, and broadly increase women’s labor force participation as additional care services become available. The gender employment gap would shrink, whereas investment in the male-dominated construction sector would only increase the gender gap.  Investing in care, and generating greater economic gains than construction-oriented stimulus, can also better reduce public deficits and debt than austerity policies, evidence shows. Strengthening the care economy would build long-term systemic resilience, build human capital, safeguard children and the elderly, and support a more diverse, inclusive, and productive workforce.</p>

			<p><a href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/documents/publication/wcms_751785.pdf" target="_blank" rel="noreferrer">Sectoral policies and incentives</a> should also strike a careful balance between both directly supporting other feminized sectors that have been hit hardest by the pandemic and generating decent employment opportunities for unemployed and underemployed women in fast-growing sectors, such as those in science, technology, engineering, and math (STEM) fields. Hiring subsidies for companies and public employment services (PES) that have explicit gender equity requirements, for example, could boost women’s (re)integration to productive employment. </p>
			<p>Simply creating job opportunities for women will not suffice, though, because a key barrier to overcome is the <a href="https://www.g20-insights.org/policy_briefs/covid-19-response-strategies-addressing-digital-gender-divides/#_edn14" target="_blank" rel="noreferrer">gender digital divide</a>, which excludes women as both creators and users of technology. As such, policies and incentives should be accompanied by programs that help close digital literacy and skills gaps and remove barriers to entry so that women can remain competitive in the labor market. Publicly funded technical and vocational training and job placement programs to re-skill and up-skill displaced workers should plan purposefully for gender equity and be tailored to ensure access and participation of individuals from populations that are at greater risk of being left behind. <a href="http://www.feministrecovery.ca/" target="_blank" rel="noreferrer">Civil society organizations and academics in Canada</a>, for example, have championed the implementation of the Calls to Action in the Truth and Reconciliation Commission, and the Calls for Justice in the Missing and Murdered Indigenous Women and Girls Inquiry Report to ensure that Indigenous people, especially women, girls, and gender minorities, have equitable access to jobs, training, and education opportunities, and that they gain long-term benefits from economic development projects. These efforts could also help pave the way for increasing women’s familiarity with digital technology for health, broadening access to services, including those for gender-based violence, and distribution of critical information.  </p>
			

			<Callout
				color="#000"
				header="Closing the gender digital divide and leaving no one behind:"
				maxWidth="100%"
				variation="graphic_style"
			>
				<p>Publicly funded technical and vocational training and job placement programs to re-skill and up-skill displaced workers should </p>
				<ul>
					<li>Plan purposefully for gender equity, and </li>
					<li>Be tailored to ensure access and participation of individuals from populations that are at greater risk of being left behind.</li>
				</ul>
			</Callout>

			<p>Governments should also support paid sick leave policies, as well as introduce or expand paid family and parental leave so parents can take time off from paid work <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">to care for children during COVID-19</a>.  Additionally, a number of labor market policies should be implemented to increase protections for wage workers in the informal economy, such as establishing minimum wage rates for hourly, daily, monthly, and piece-rate work; clarifying and institutionalizing relations among employers, contractors, and workers; and increasing transparency and monitoring in hiring and dismissal. Further, policy and legal frameworks to eliminate gender pay gaps and gender-based discrimination, and to hold the private sector accountable, are more important now than ever. </p>

			<Callout
				color="#000"
				header="Labor market policies to increase protections for wage workers in the informal economy:"
				maxWidth="100%"
				variation="graphic_style"
			>
				<ul>
					<li>Establishing minimum wage rates for hourly, daily, monthly, and piece-rate work;</li>
					<li>Clarifying and institutionalizing relations between employers, contractors, and workers; and</li>
					<li>Increasing transparency and monitoring in hiring and dismissal.</li>
				</ul>
			</Callout>

			<Slider color="#F2A93B" />

			<FullWidthSection style={{backgroundColor: '#ebe8e3', padding: '2em 0 4em'}}>
				<ContainedContent>
					<p className="image text-center show-for-desktop"><img src={data_viz_2_2_box_9_desktop} alt="Economic Recovery and the Gender-Environment Nexus" style={{maxHeight: '90vh'}} /></p>
					<p className="image text-center show-for-mobile"><img src={data_viz_2_2_box_9_mobile} alt="Economic Recovery and the Gender-Environment Nexus" /></p>

					<p className="font-graphics text-center"><strong>Figure 2: Economic Recovery and the Gender-Environment Nexus</strong></p>
					<p className="font-graphics">Governments should also seize this moment to take action to limit the long-term threat of climate change, which is already affecting global prosperity and driving millions of people, particularly women, into poverty. <a href="https://www.genderandcovid-19.org/wp-content/uploads/2020/10/Gender-and-COVID-19-feminist-recovery-plans.pdf" target="_blank" rel="noreferrer">Implementing stimulus programs</a> that focus on low-carbon green jobs and training and transitioning women into the historically male-dominated clean energy sector would contribute to creating vibrant economies grounded in social and environmental sustainability. Publicly funded training on the use of green technologies that target women entrepreneurs could also ease their transition  to green economy. The <a href="https://www.tandfonline.com/doi/full/10.1080/13545701.2020.1867762" target="_blank" rel="noreferrer">care economy</a> is also central to the gender-environment nexus. <a href="http://feministgreennewdeal.com/wp-content/uploads/2021/04/FemGND-IssueBrief-Draft7-Apr15.pdf" target="_blank" rel="noreferrer">Investments in the care sector</a> would both unburden women of their unpaid care responsibilities and enable them to take green jobs in the clean energy sector while providing new sources of employment for workers transitioning out of fossil fuel sectors.</p>
				</ContainedContent>
			</FullWidthSection>

			<p>Revitalizing women-owned businesses will require both supply and demand interventions. On the supply side, it will be critical to close the long-standing—and widening—gender funding gap. <a href="https://www.ifc.org/wps/wcm/connect/03522e90-a13d-4a02-87cd-9ee9a297b311/121264-WP-PUBLIC-MSMEReportFINAL.pdf?MOD=AJPERES&CVID=m5SwAQA" target="_blank" rel="noreferrer">According to the International Finance Corporation (IFC)</a>, 70 percent of formal women-owned enterprises in developing economies are either excluded by financial institutions or are unable to access financial services that meet their needs, representing a $1.7 trillion gender funding gap. Governments must ensure that the large capital infusions directed to SMEs as part of COVID-19 recovery plans do not end up allocated disproportionately to men-owned businesses.  Governments should channel support to the hard-hit sectors in which women-owned businesses are concentrated, as well as support and stimulate women’s social enterprises, particularly those in the care economy, as they seek to develop solutions to social, cultural, and environmental issues. </p>

			<CalloutSecondary
				color="#23575e"
				pre_header=""
				header="70%"
				text="of formal women-owned enterprises in developing economies are either excluded by financial institutions or are unable to access financial services that meet their needs, representing a $1.7 trillion gender funding gap."
			/>

			<p>Efforts should also be made to facilitate the access of women entrepreneurs to information and communications technology (ICT) and financial services with targeted low-interest loans, deferred payments, and tax exemptions. Some governments are offering training programs that enable women-led businesses to adapt, up-skill, and crossover to new sectors during the pandemic. For example, the government of Indonesia launched and later doubled the allocated budget from $668 billion to $1.3 trillion of Kartu Prakerja, a program that provides subsidized vouchers for unemployed workers for up-skilling and re-skilling. It aims to reach an estimated 5.6 million informal workers and small and microenterprises.  </p>
			<p>On the demand side, governments can act with minimum set-asides in public procurement spending towards businesses led by women, amending government contracting and procurement policies to create incentives for contractors to hire women or women-owned businesses, and encouraging private sector efforts to source from women-owned businesses through tax breaks and other incentives.</p>

			<Callout
				color="#000"
				header="Demand-side Government Interventions to Support Women-led Enterprises:"
				maxWidth="100%"
				variation="graphic_style"
			>
				<ul>
					<li>Minimum set-asides in public procurement spending;</li>
					<li>Amending government contracting and procurement policies to create incentives for contractors to hire women or women-owned businesses; and</li>
					<li>Encouraging private sector efforts to source from women-owned businesses through tax breaks and other incentives</li>
				</ul>
			</Callout>

			<p>Government procurement from women-led collective enterprises, including self-help groups, would enable these aggregated enterprises to revive their businesses and, in turn, support their communities. To do so, though, procurement processes must match their abilities and needs. Systems requiring working capital and digital literacy to submit a bid may pose a barrier for women-led enterprises.  Other issues may also arise. <a href="https://aajeevika.gov.in/sites/default/files/nrlp_repository/COVID-19%20Response%20by%20Women%20SHG%20Warriors.pdf" target="_blank" rel="noreferrer">In India</a>, for example, as of July 2020, thousands of women in SHGs produced a total of 224.65 million masks, nearly $49,000 worth of PPE, nearly $6,600 worth of hand sanitizer, and 104,521 liters of hand wash. However, challenges related to sales and payment delays indicate a need to strengthen and support public procurement through SHGs in general, and to establish dedicated market linkages for engaging with health-, nutrition-, and sanitation-related enterprises for long-term sustainability.</p>
			<p>Importantly, no one-size-fits-all solution exists for recovery. Support for women, particularly those in the informal sector, will only be effective if it combines purposeful measures that meet their needs on multiple fronts. For example, the <a href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/---emp_ent/---coop/documents/presentation/wcms_743025.pdf" target="_blank" rel="noreferrer">Self-Employed Women’s Association (SEWA) in India</a>, a trade union of 1.7 million informal women workers that has organized 120 women’s cooperatives nationwide, is advocating for a livelihood restoration fund; universal social protection, health care, and child care; provision of identity cards for all informal workers; access to working capital, including low-interest loans; the restoration of supply chains reliant on women’s work; and publicly funded programs to facilitate greater digital inclusion. </p>

			<p><img src={informal_economy_2_2} alt="Support for Women in the Informal Economy" width="100%" /></p>
			<Callout
				color="#000"
				header="Support for Women in the Informal Economy:"
				maxWidth="100%"
				variation="graphic_style"
			>
				<p className="font-graphics">A call to action from the self-employed women’s association in India</p>
				<ul>
					<li>A livelihood restoration fund; </li>
					<li>Universal social protection, health care, and child care; </li>
					<li>Provision of identity cards for all informal workers; </li>
					<li>Access to working capital, including low-interest loans;</li>
					<li>The restoration of supply chains reliant upon women’s work; and </li>
					<li>Publicly funded programs to facilitate greater digital inclusion.</li>
				</ul>
			</Callout>

			<p>Similarly, the <a href="https://idwfed.org/en/updates/global-idwf-statement-on-protecting-domestic-workers-rights-and-fighting-the-coronavirus-pandemic" target="_blank" rel="noreferrer">International Domestic Workers Federation</a> is urging governments to protect domestic workers’ rights to a safe and hazard-free workplace, including the provision of PPE; paid sick leave and access to health care; fair, legal, and contractual wages and compensation; information about workplace health and safety; and worker rights.</p>

			<div id="section-2-3" />
			<h3 className="header-h3">2.3 Cross-Cutting Issue: Addressing the Care Crisis</h3>
			<p>Care work, both paid and unpaid, sustains the well-being of our social, economic, and health systems. The pandemic-related increases in unpaid work and growing deficits in paid, decent, and quality care jobs are deepening gender and other inequalities—including by driving women out of the workforce—and threaten long-term economic recovery and growth. The <a href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/---publ/documents/publication/wcms_633135.pdf" target="_blank" rel="noreferrer">ILO’s 5R Framework for Decent Care Work</a> (Figure 2) has emerged as a useful framework to conceptualize and advocate for a comprehensive policy agenda to address the care crisis and accelerate progress toward recovery from the pandemic. It calls for recognizing, reducing, and redistributing unpaid care work; rewarding paid care work, by promoting more and decent work for care workers; and guaranteeing care workers’ representation, social dialogue, and collective bargaining.</p>
			<p>To expand upon the main policy areas identified by the original 5R framework and to highlight the knock-on effects that government action can have across the 5Rs of the care agenda, FP Analytics has identified seven areas for government action: social protections (including health policies), labor policies, macroeconomic policies and government spending, migration policies, data systems, education policies, and community mobilization and participation. Recommendations for both short- and long-term measures and illustrative examples are presented within these action areas, noting which of the 5Rs they may help facilitate. This is not a comprehensive list, and the recommendations are not universally applicable to all country contexts. Policymakers must undertake careful context-specific care analyses and make evidence- and rights-based decisions to ensure an equitable and sustainable care economy. </p>

			<FullWidthSection style={{backgroundColor: '#ebe8e3', padding: '2em 0 4em'}}>
				<ContainedContent>
					<p className="font-graphics text-center"><strong>Figure 3: Adapted 5R Analytical and Policy Frameworks</strong></p>

					<Spotlight
						image={data_viz_2_3_5R_desktop}
						mobileImage={data_viz_2_3_5R_mobile}
						imageAlt="Government Action Areas"
						imageBg="#fff"
						imagePadding="2em"
						color=""
						textColor="#000"
						header=""
					/>

					<p className="font-graphics text-center"><strong>Government Action Areas, Recommendations, and Examples</strong></p>
					<p className="font-graphics text-center instructional-subhead space-bottom">Click to explore recommendations, examples, and how they meet the 5Rs framework</p>

					<ExpandableSection title="Social Protections and Health Policies" color="#ED752E">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Provide targeted support and expand social protections to all care workers, including domestic workers, in both formal and informal employment.</p>
							<p><strong>Example:</strong> Cash transfers, food programs, subsidies or waivers for rent and utilities, and health care coverage.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Increase care support for households, including targeted support for women who left the labor market because of COVID-19.</p>
							<p><strong>Example:</strong> <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">In Poland</a>, parents affected by COVID-19 care service and school closures are entitled to 14 additional days of child care allowance.</p>
							<p><strong>Example:</strong> Child care allowances, vouchers, and “cash-for-care” programs have been introduced in response to COVID-19  in the <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Cook Islands, Czechia, Germany, Italy, Malta, and Spain</a>.</p>
							<p><strong>Example:</strong> <a href="https://wbg.org.uk/wp-content/uploads/2020/10/WBG-Report-v10.pdf" target="_blank" rel="noreferrer">Increase care workers’ allowance</a> for those providing long-term unpaid care.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Extend social assistance and other forms of social protection to nonstandard and  informal workers.</p>
							<p><strong>Example:</strong> <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">In Argentina</a>, a new cash transfer program – the Ingreso Familiar de Emergencia – is expected to reach 3.6 million families of informal, self-employed, and domestic workers impacted by COVID-19.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Support <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">gender-responsive public services</a> and care-related infrastructure.</p>
							<p><strong>Example:</strong> Provide free or subsidized utilities – such as water, gas, and electricity – or temporarily reduced fees and deferrals for bills.</p>
							<p><strong>Example:</strong> Prohibit basic services from being cut off for non-payment.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
							</ul>
							<p><strong>Recommendation:</strong> Work toward Universal Health Coverage (UHC) to provide health care access to all paid and unpaid care workers.</p>
							<p><strong>Example:</strong> Health systems should be restructured to focus on UHC and improve health service access and quality to <a href="https://www.bmj.com/content/371/bmj.m3384" target="_blank" rel="noreferrer">include women who are unemployed, informally employed, in nonstandard employment, and/or migrants</a>.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Labor Policies" color="#FFA8A3">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Pass and enforce equal pay legislation and laws that prevent workplace discrimination.</p>
							<p><strong>Example:</strong>  In July 2020, <a href="https://www.mbie.govt.nz/business-and-employment/employment-and-skills/employment-legislation-reviews/equal-pay-amendment-act/" target="_blank" rel="noreferrer">New Zealand</a> unanimously passed the Equal Pay Amendment Bill, which ensures women in historically underpaid female-dominated industries receive the same remuneration as men in different but equal-value work.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong>  Introduce or expand paid parental and family leave and sick leave for both formal and informal workers, including domestic workers.</p>
							<p><strong>Example:</strong> Wage subsidies have been introduced for  those caring for ill family members during COVID-19 in <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Montenegro, Latvia, Cuba, and Germany.</a></p>
							<p><strong>Example:</strong> One working parent can access paid leave at full pay for the duration of school and kindergarten shutdowns in <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Uzbekistan.</a></p>
							<p><strong>Example:</strong> I Provide incentives for men to take parental and family leave.</p>
							<p><strong>Example:</strong>  Domestic workers can access paid leave for the duration of <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Argentina's</a> mandatory lockdown.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Expand unemployment insurance and income assistance to include domestic workers.</p>
							<p><strong>Example:</strong> Domestic workers are being included in national unemployment insurance in <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Chile.</a></p>
							<p><strong>Example:</strong>  In <a href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf" target="_blank" rel="noreferrer">Italy</a>, €500 was paid to domestic workers with contracts who did not receive their salary  April to May 2020.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong>  Increase employer adoption of family friendly workplace policies in both the formal and informal sector.</p>
							<p><strong>Example:</strong>  Pass legislation requiring companies to support or provide child care with quality standards and guidelines. Private-sector employers are legally required to support or provide child care in just <a href="https://pubdocs.worldbank.org/en/459771566827285080/WBL-Child-Care-4Pager-WEB.pdf" target="_blank" rel="noreferrer">26 out of 189 countries studied</a> by the Women, Business and the Law Programme.</p>
							<p><strong>Example:</strong> Incentivize companies to provide generous parental leave policies with subsidies.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
							</ul>
							<p><strong>Recommendation:</strong> Provide all care workers with PPE. </p>
							<p><strong>Example:</strong> Exempt care workers from lockdown restrictions so they can travel to and from work without fear of legal consequences.</p>
							<p><strong>Example:</strong>  Provide information to domestic workers about how to stay safe and protect their rights.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Expand and enforce legal protections for domestic workers.</p>
							<p><strong>Example:</strong> Hong Kong made it unlawful under the Disability Discrimination Ordinance for employers to dismiss domestic workers if they contract COVID-19.</p>
							<p><strong>Example:</strong> All countries should ratify <a href="https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C189" target="_blank" rel="noreferrer">ILO Convention C189</a> concerning decent work for domestic workers.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Provide public works for care workers.</p>
							<p><strong>Example:</strong> In <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">South Africa</a>, the Expanded Public Works Programme (EPWP) provides training and employment opportunities for more than 20,000 women to care for children at home or in schools, providing care and support for more than 185,000 children.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Expand eligibility for short-term work programs to include workers in precarious labor market positions – including care workers and women with unpaid care responsibilities.</p>
							<p><strong>Example:</strong> In <a href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf" target="_blank" rel="noreferrer">Switzerland</a>, employees with precarious employment contracts have been included in the short-term work program.</p>
							<p><strong>Example:</strong> In <a href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf" target="_blank" rel="noreferrer">Slovakia</a>, approximately €21 million Euros were dedicated to subsidizing 80 percent of kindergarten employees’ average wages for April, May, and June 2020.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Provide wage subsidies for care workers.</p>
							<p><strong>Example:</strong>  In <a href="https://www.un.org/development/desa/family/wp-content/uploads/sites/23/2020/09/Duragova.Paper_.pdf" target="_blank" rel="noreferrer">Montenegro</a>, the government introduced wage subsidies at 70 percent of minimum wage for employees on paid leave who have to stay home to care for a child.</p>
							<p><strong>Example:</strong> In <a href="https://www.un.org/development/desa/family/wp-content/uploads/sites/23/2020/09/Duragova.Paper_.pdf" target="_blank" rel="noreferrer">the Czech Republic</a>, the government offers up to 100 percent of average wage compensation to companies when their employees face child care obstacles.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Macroeconomic Policies and Government Spending" color="#F2A93B">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Undertake gender-responsive budgeting and macroeconomic "care analysis" to identify and correct gender and care provision biases in budgetary and tax policy, including fiscal stimulus packages.</p>
							<p><strong>Example:</strong>  <a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2021/policy-brief-covid-19-and-fiscal-policy-en.pdf?la=en&vs=3544" target="_blank" rel="noreferrer">Morocco</a>, with UN Women’s support, conducted a household survey to assess the socioeconomic impacts of lockdown measures, including household work, and integrated findings into the 2020-2021 Gender Report that accompanies the annual Fiscal Bill.  	</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> <a href="https://wbg.org.uk/wp-content/uploads/2020/10/WBG-Report-v10.pdf" target="_blank" rel="noreferrer">Coordinate fiscal and monetary policy</a> to avoid austerity measures and create and sustain a caring economy.</p>
							<p><strong>Example:</strong> The UN Human Rights Council’s <a href="https://www.undocs.org/A/HRC/40/57" target="_blank" rel="noreferrer">Guiding Principles on Human Rights Impact Assessments of Economic Reforms</a> calls for public investments in care work and macroeconomic reforms that transform existing gender inequalities instead of harmful austerity measures.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong>  Increase public spending in the care sector.</p>
							<p><strong>Example:</strong> <a href="https://www.canada.ca/en/department-finance/news/2021/04/budget-2021-supporting-women.html" target="_blank" rel="noreferrer">Canada</a> is mobilizing CAD $30 billion over the next five years to build a nationwide early learning and child care system.</p>
							<p><strong>Example:</strong>  <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Mexico’s Estancias program</a>, run by the Mexican government and four UN agencies, provides quality and affordable child care to mostly low-income households. It covers up to 90 percent of the cost for parents and employs up to 40,000 women as child care providers.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Work towards providing universal, publicly funded child care in the long term.</p>
							<p><strong>Example:</strong>  Expand existing publicly funded child care programs and invest in the provision of center- and community-based services, day care, preschool, and after-school programs.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Formulate and resource a national care plan that provides a comprehensive policy framework to address the care crisis.</p>
							<p><strong>Example:</strong> Significant progress has been made in <a href="https://www2.unwomen.org/-/media/field%20office%20americas/documentos/publicaciones/2019/10/snic%20web%20ingles.pdf?la=en&vs=2843" target="_blank" rel="noreferrer">Uruguay</a> under the National Care Plan 2016-2020, including the establishment of a National Integrated Care System (NICS) and cross-ministerial National Care Board.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
								<li className="color-representation">REPRESENTATION</li>
							</ul>
							<p><strong>Recommendation:</strong> Provide targeted support to care enterprises.</p>
							<p><strong>Example:</strong> Provide subsidies or waivers for rent and utilities, funding to offset increased operating costs and lower revenues, and wage subsidies.</p>
							<p><strong>Example:</strong> <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Norway, Slovakia, Switzerland, and some states in the U.S.</a> included direct financial support to care enterprises in pandemic response packages.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Stimulate private spending in the care sector using child care allowances, vouchers, and “cash-for-care” programs.</p>
							<p><strong>Example:</strong> Under the <a href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf" target="_blank" rel="noreferrer">Cura Italia program in Italy</a>, a child care voucher of up to €600 was made available for private-sector workers with children below 12 who decided not to take parental leave.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Mobilize overseas development aid (ODA) and coordinate aid from international organizations to finance care initiatives.</p>
							<p><strong>Example:</strong> The <a href="https://www.actionaid.org.uk/our-work/womens-economic-rights/unpaid-care-and-domestic-work" target="_blank" rel="noreferrer">ActionAid POWER project</a> supports women’s groups in Bangladesh, Ghana, and Rwanda advocating for government action to address women’s unpaid care and domestic work, including investments in time-saving initiatives like building water sources and opening child care centers.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Migration Policies" color="#B86836">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Grant amnesty and visa extensions to migrant domestic workers.</p>
							<p><strong>Example:</strong> Portugal has granted all migrants and asylum seekers, including migrant domestic workers, temporary access to citizenship rights during COVID-19.</p>
							<p><strong>Example:</strong> Italy facilitated the regularization of undeclared immigrants working in personal and domestic help.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Terminate unfair immigration laws</p>
							<p><strong>Example:</strong> Governments should <a href="https://idwfed.org/en/covid-19/the-idwf/advocacy/idwf-recommendations.pdf" target="_blank" rel="noreferrer">uphold their commitments</a> to the International Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong>  <a href="https://idwfed.org/en/covid-19/the-idwf/advocacy/idwf-recommendations.pdf" target="_blank" rel="noreferrer">Promote safe migration procedures</a> and implement official migration mechanisms that guarantee access to justice and legal support.</p>
							<p><strong>Example:</strong>  <a href="https://idwfed.org/en/covid-19/the-idwf/advocacy/idwf-recommendations.pdf" target="_blank" rel="noreferrer">Facilitate travel and provide legal and financial exceptions</a> for migrant domestics workers to cross national borders during COVID-19 closures.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Data Systems" color="#2A63D4">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Develop methodological guidelines and approaches for the collection of time-use and other data that capture gender differences in time spent on unpaid care tasks.</p>
							<p><strong>Example:</strong> The Classification of Time-Use Activities for Latin America and the Caribbean (CAUTAL) and the Harmonized European Time Use Survey (HETUS) are <a href="https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf" target="_blank" rel="noreferrer">two regional efforts</a> to harmonize time-use data.</p>
							<p><strong>Example:</strong> The <a href="https://unstats.un.org/unsd/demographic-social/time-use/time-use-expert-group/#ws" target="_blank" rel="noreferrer">UN Expert Group on Innovative and Effective Ways to Collect Time-Use Statistics</a> was initiated in 2018 to develop guidelines for the International Classification of Activities for Time-Use Statistics (ICATUS).</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Collect time-use and other data under COVID-19 and monitor whether short-term changes observed impact in the medium and long term.</p>
							<p><strong>Example:</strong> <a href="https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf" target="_blank" rel="noreferrer">The U.S., Australia, Canada, France, Netherlands, Norway, South Korea, Sweden, and the UK</a> collect time-use data on a regular basis and have opportunities to study shifts over time.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Conduct a nationwide child care needs assessment.</p>
							<p><strong>Example:</strong> Include data on the size of the child care sector; range of providers; types and conditions of child care workers in both formal and informal employment; and accessibility, affordability, and quality of available child care services.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>
							<p><strong>Recommendation:</strong> Share data and best practices within and between countries.</p>
							<p><strong>Example:</strong> In <a href="https://data2x.org/wp-content/uploads/2020/01/Impact-Case-Studies-Finland-6P.pdf" target="_blank" rel="noreferrer">Finland</a>, multiple government agencies – including education, environment, transport, and communications, and the Social Insurance Institute – fund the nationwide time-use survey, serve as advisors to shape questions, and use the data for policymaking.</p>
							<p><strong>Example:</strong> Share data on time-use and the child care sector and any cost-effective data collection methods with other countries, particularly those with similar socioeconomic contexts.</p>
							<p><strong>Example:</strong> Share evidence of the “business case” for employer-supported child care, advertising the types of support available to employers.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Use innovative participatory data collection methods to ensure the perspectives of care workers, paid and unpaid, are included.</p>
							<p><strong>Example:</strong> <a href="https://opendocs.ids.ac.uk/opendocs/bitstream/handle/20.500.12413/16439/Assessing_Unpaid_Care_Work_A_Participatory_Toolkit.pdf?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">The Institute of Development Studies</a> developed a participatory data collection toolkit to understand the social norms and perspectives of communities in India, Nepal, Tanzania, and Rwanda regarding unpaid care work, including the role of governments.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
								<li className="color-representation">REPRESENTATION</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Education Policies" color="#52B66B">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Reopen schools and child care facilities safely, and provide adequate technical and financial assistance.</p>

							<p><strong>Example:</strong> <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Use measures</a> such as physical distancing; reducing the number of children in a classroom; mandating students and teachers wear masks and wash their hands frequently; sanitizing and adequately ventilating schools;  and implementing robust testing and contact tracing of COVID-19 cases.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-reward">REWARD</li>
							</ul>

							<p><strong>Recommendation:</strong> Provide support to parents with children engaged in remote learning.</p>
							<p><strong>Example:</strong> Provide information and support to parents to help manage additional child care tasks.</p>
							<p><strong>Example:</strong> Adopt SMS, phone calls, and other widely accessible, affordable, and low-technology methods of information delivery to <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">engage and support parents</a> in supervising  children’s remote learning.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
							</ul>
						</div>
					</ExpandableSection>

					<ExpandableSection title="Community Mobilization and Participation" color="#DB4832">
						<div className="font-graphics">
							<p><strong>Recommendation:</strong> Shift social norms about care work as "women's work."</p>

							<p><strong>Example:</strong> Workshops, mass and social media campaigns, and school-based curricula can <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">increase the involvement of men and boys</a> in unpaid care work.</p>
							<p><strong>Example:</strong> Religious and community leaders can act as “<a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">care champions</a>” to motivate more men and boys to participate in unpaid care work.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-recognize">RECOGNIZE</li>
							</ul>

							<p><strong>Recommendation:</strong> Promote the rights and safety of domestic workers.</p>
							<p><strong>Example:</strong> <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Colombia, Costa Rica, Ecuador, and Mexico</a> are launching national awareness campaigns on guaranteeing the rights and safety of domestic workers.</p>
							<ul className="tags">
							<li className="color-reduce">REDUCE</li>
							</ul>
							<p><strong>Recommendation:</strong> Engage care workers in both formal and informal employment, including domestic workers, in social dialogue with employers and governments.</p>
							<p><strong>Example:</strong> In <a href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf" target="_blank" rel="noreferrer">Ecuador</a>, an inter-institutional roundtable was set up to support the rights of domestic workers, with the participation of the Ministry of Labor, the National Council for Gender Equality, ILO, UN Women, and domestic worker unions.</p>
							<p><strong>Example:</strong> There has been social dialogue on COVID-19 measures and the needs of the paid care sector between the <a href="https://unece.org/sites/default/files/2021-01/PBrief_Care%20Economy_Covid%20response_08012021_1.pdf" target="_blank" rel="noreferrer">European Public Service Union and the European Federation of Social Employers</a>, focusing specifically on long-term care services.</p>
							<ul className="tags">
								<li className="color-reduce">REDUCE</li>
								<li className="color-representation">REPRESENTATION</li>
							</ul>
							<p><strong>Recommendation:</strong> Promote freedom of association and collective bargaining for care workers.</p>
							<p><strong>Example:</strong> <a href="https://www.ilo.org/wcmsp5/groups/public/---americas/---ro-lima/documents/publication/wcms_534457.pdf" target="_blank" rel="noreferrer">Uruguay</a> has implemented a sustained strategy of interventions to support domestic workers, including social security, a minimum wage, collective bargaining mechanisms, and labor inspections.</p>
							<ul className="tags">
								<li className="color-reward">REWARD</li>
								<li className="color-representation">REPRESENTATION</li>
							</ul>
							<p><strong>Recommendation:</strong> Promote the building of alliances between trade unions representing care workers and civil society organizations representing care recipients and unpaid caregivers.</p>
							<p><strong>Example:</strong> <a href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/---publ/documents/publication/wcms_633135.pdf" target="_blank" rel="noreferrer">Women in Informal Employment: Globalizing and Organizing (WIEGO)</a> – which consists of membership-based organizations, researchers, and practitioners from development agencies – facilitates services, research, and policy advocacy for the informal workforce, including paid and unpaid care workers.</p>
							<ul className="tags">
								<li className="color-recognize">RECOGNIZE</li>
								<li className="color-reduce">REDUCE</li>
								<li className="color-redistribute">REDISTRIBUTE</li>
								<li className="color-reward">REWARD</li>
								<li className="color-representation">REPRESENTATION</li>
							</ul>
						</div>
					</ExpandableSection>

				</ContainedContent>
			</FullWidthSection>

			<div id="section-2-4" />
			<h3 className="header-h3 tight-top">2.4 Gender-Responsive and Sustainable Financing for Recovery</h3>
			<p>Policymakers should increase domestic resource mobilization and design sustainable financing strategies to improve gender equity in COVID-19 recovery efforts, whether through fiscal reforms to raise more public funding or by increasing the prioritization of women in budgets at national and sub-national levels.</p>
			<p>When exploring alternative fiscal policy measures, governments must ensure that all structural adjustments undergo rigorous intersectional gender analysis by funding gender impact assessment to understand how policies affect different populations, particularly in low- and middle-income households. For example, in designing the fiscal response to the current crisis, <a href="https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/" target="_blank" rel="noreferrer">the government of Iceland</a> instructed its ministries to detail how potential investments might benefit women and men differently to assess the overall package’s impact on gender equality objectives. </p>

			<div className="font-graphics" style={{ backgroundColor: '#DB4832', margin: '3em auto' }}>
				<div className="space-around-section">
					<Spotlight
						color="#DB4832"
						stacked="true"
					>
						<h2><strong>Gender-Responsive Budgeting in Action: Canada</strong></h2>
						<p className="big-number">$601.3M</p>
						<p>Canada’s 2021 budget highlights the investments it will make with the aim of “creating a more inclusive, sustainable, feminist, and resilient economy that values women’s work.” These commitments include $601.3 million over five years to advance towards a new National Action Plan to End Gender-Based Violence and almost $30 billion over the next five years to build a nationwide early learning and child care system.</p>
					</Spotlight>

				<Spotlight
					color="#DB4832"
					stacked="true"
				>
					<h2><strong>More and Better Funding for Women’s Organizations in Low- and Middle-Income Countries</strong></h2>
					<p className="big-number">€40M</p>
					<p>In 2017, the Netherlands launched the €40 million “Leading from the South” fund, which supports women’s organizations in Africa, the Middle East, Asia and the Pacific, and Latin America and the Caribbean, and is administered by regional women’s funds that can facilitate connections to local organizations and lend support in navigating procurement and reporting requirements.</p>
				</Spotlight>
				</div>

			</div>

			<p>To better prioritize women, and mobilize adequate resources to address their needs, governments should use <a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2021/policy-brief-covid-19-and-fiscal-policy-en.pdf?la=en&vs=3544" target="_blank" rel="noreferrer">gender-responsive budgeting (GRB)</a>, which analyzes, prepares, and implements budgets from a gender perspective. For example, Canada recently published its 2021 budget, in which it highlights the investments it will make with the aim of “creating a more inclusive, sustainable, feminist, and resilient economy that values women’s work.” <a href="https://www.canada.ca/en/department-finance/news/2021/04/budget-2021-supporting-women.html" target="_blank" rel="noreferrer">These commitments</a> include $601.3 million over five years to advance towards a new National Action Plan to End Gender-Based Violence and almost $30 billion over the next five years to build a nationwide early learning and child care system. </p>
			<p>GRB can and should also be applied to overseas development aid (ODA), which will be essential to pandemic recovery efforts as greater overall aid flows can provide more fiscal space in low- and middle-income countries to address women’s vulnerabilities during pandemic recovery. Donor countries should ensure that ODA accounts for gender considerations across all key country priorities. As with domestic financing strategies and budgetary allocations, all funding proposals and impact assessments should contain comprehensive intersectional gender analyses and gender mainstreaming provisions. Governments should also increase and streamline funding to community-based and women-led organizations operated by those who know and are embedded in the local context. For example, in 2017, the Netherlands launched the €40 million <a href="https://www.government.nl/latest/news/2017/02/09/netherlands-invests-in-boosting-women%E2%80%99s-organisations-in-southern-countries" target="_blank" rel="noreferrer">“Leading from the South”</a> fund, which supports women’s organizations in Africa, the Middle East, Asia and the Pacific, and Latin America and the Caribbean, and is <a href="https://www.cgdev.org/sites/default/files/from-priciples-practice-gender-equality.pdf" target="_blank" rel="noreferrer">administered by regional women’s funds</a> that can facilitate connections to local organizations and provide technical support in navigating procurement and reporting requirements. </p>

			<div id="section-2-5" />
			<h3 className="header-h3">2.5 Gender Data and Knowledge Generation</h3>
			<p>UN Women and other local and global stakeholders committed to accelerating progress toward achieving the UN Sustainable Development Goals, and that are now focused on recovering from the pandemic, recognize that “the <a href="https://www.unwomen.org/en/digital-library/publications/2018/2/gender-equality-in-the-2030-agenda-for-sustainable-development-2018" target="_blank" rel="noreferrer">women and girls who are furthest behind</a> often experience multiple inequalities and intersecting forms of discrimination, including those based on their sex, age, class, ability, race, ethnicity, sexual orientation, gender identity, and migration status.” To reach those at greatest risk of being left behind, all COVID-19 response and recovery policies and programs, as well as the budgets to resource them, must be underpinned by timely, quality, comparable, and reliable intersectional gender data on COVID-19, and in areas where women’s and girls’ lives are disproportionately affected by pandemic.</p>
			
			<Callout
				color="#000"
				header="Gender Data: A Call to Action for Governments"
				maxWidth="100%"
				variation="graphic_style"
			>
				<ul>
					<li>Make use of existing gender data and evidence;</li>
					<li>Prioritize and generate demand for new gender data and evidence;</li>
					<li>Support responsible and ethical innovations;</li>
					<li>Support the standardization and expansion of gender data collection, reporting, use, and sharing; and </li>
					<li>Designate adequate resources to improve infrastructures, capacities, and practices.</li>
				</ul>
			</Callout>
			
			<p>Policymakers have key roles to play in the knowledge generation process by using existing gender data and evidence; prioritizing and generating demand for new gender data and evidence; supporting the development and deployment of responsible and ethical innovations, including those for the use of nontraditional gender data to fill critical gender data gaps; supporting the standardization and expansion of gender data collection, reporting, use, and sharing; and creating an enabling environment by designating adequate resources to improve gender data infrastructures, capacities, and practices. </p>
			<p>To ensure that policymakers’ decisions are evidence-based and gender-responsive, gender equality advocates are calling for a greater <a href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf" target="_blank" rel="noreferrer">shift in the data production-use paradigm</a>—for “bidirectional engagement,” or consistent sharing between data producers and users —to ensure that data reflect women’s and girls’ needs in real time, as well as to build greater public trust in data. Efforts should also be made to shift power imbalances in the research ecosystem by, for example, supporting more gender-equitable distribution of research funding, decision-making, and capacity-building within and across countries, and funding participatory action research that meaningfully engages communities, as opposed to treating them as objects under study. </p>
			<p>When populations are treated as passive recipients of policies, programs, and aid and decisions are made based on assumptions, rather than demand, actions taken by governments may be unsustainable, inefficient, inequitable, or even have unintended consequences that further compromise the well-being of marginalized subgroups. Policymakers should move beyond traditional consultations with stakeholders to a stronger co-creation and shared decision-making model that meaningfully engages women, girls, and gender minorities as agents of change in their communities, acknowledges their self-identified needs, harnesses their knowledge and expertise of the local context, and provides them with the necessary resources and tools to overcome the barriers they face in both the short and long term. </p>

			<FullWidthSection style={{backgroundColor: '#ebe8e3', padding: '0', marginBottom: '-0.5em', marginTop: '4em'}}>
				<ContainedContent>
					<Spotlight
						variation="no_padding"
						color=""
						textColor="#000"
						header="Figure 4: Investing in Gender Data: Supporting the Enabling Environment"
					>
						<p>Gender data suffers from chronic underfunding and lack of prioritization. <a href="https://paris21.org/sites/default/files/inline-files/PRESS%202020%20Final.pdf" target="_blank" rel="noreferrer">In 2018</a>, only 6 percent of all statistical projects financed by bilateral donors noted gender equality as a primary objective, and less than 12 percent of bilateral donor commitments were directed towards gender statistics activities—only a fraction of which was designated for activities focused primarily on gender data. In the wake of COVID-19, <a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/gender-equality-in-the-wake-of-covid-19-en.pdf?la=en&vs=5142" target="_blank" rel="noreferrer">more than half of national statistics offices</a> in low- and lower-middle-income countries have experienced budget cuts, and financing gender data hardly rises to the top as a priority.  The <a href="https://data2x.org/wp-content/uploads/2021/05/State-of-Gender-Data-Financing-2021_FINAL.pdf" target="_blank" rel="noreferrer">estimated cost of core gender data systems</a> globally—which includes censuses, civil registration, and vital statistics (CRVS), and administrative data produced by health, education, and other systems—is expected to average slightly over $1 billion a year from 2021–2025 and $900 million a year from 2025–2030. </p>
						<p>Strengthening data infrastructures will be critical to building more resilient and gender-responsive systems. As such, policymakers should <a href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf" target="_blank" rel="noreferrer">support and adequately resource</a> a) statistical systems’ capacities to produce and use gender data and provide financial support to modernize administrative data collection and strengthen national survey systems, and b) gender data production and use across the National Statistical System (NSS) to fulfill national, regional, and international commitments to gender data. Common funding modalities include domestic resource mobilization, loans and grants through multilateral lending instruments, bilateral grants from donor countries and philanthropic organizations, multidonor or pooled trust funds, and emergency funds.</p>
						<br />
						<p className="image show-for-desktop"><img src={data_viz_2_5_box_10_desktop} alt="Gender data suffers from chronic underfunding and lack of prioritization" /></p>
						<p className="image show-for-mobile"><img src={data_viz_2_5_box_10_mobile} alt="Gender data suffers from chronic underfunding and lack of prioritization" style={{margin: '-1em auto -2em', display: 'block', width: '90%'}} /></p>
					</Spotlight>
					<div className="show-for-mobile" style={{padding: '0 0 4em'}}>
						<p className="font-graphics"><strong>Building a Core Gender Data System</strong></p>
						<p className="font-graphics">Instruments and administrative sources are added to a gender data system and produce gender indicators.</p>
						<p className="font-graphics">That data is used to improve the lives of women and girls.</p>
					</div>
				</ContainedContent>
			</FullWidthSection>
		</section>
	);
}

export default Section2;