import React from 'react';

import MidWidthSection from '../../components/MidWidthSection/MidWidthSection.js';

import SlickSliderCaregiving from '../../components/SlickSliderCaregiving/SlickSliderCaregiving.js';

import podcast_icon from '../../images/Hero-podcast-women-gender-foreign-policy-gates-foundation-logo.jpg';

import illustration_image_6 from '../../images/illustration/Box3a-The_Care_Crisis_01.jpg';

import fp_studios_logo from '../../images/logos/FP-Studios.png';
import fp_logo from '../../images/logos/FPA-revised-0607.svg';
import gates_logo from '../../images/logos/bill-melinda-gates-foundation-logo-vector.svg';

class Caregiving extends React.Component {
	render() {
		return (
			
			<div className="App-text col-8 page_caregiving">
				<h1 className="page-title page-title--no-border">An intimate look at the global childcare crisis</h1>
				<h2 className="header-h2 no-border">What’s at stake and how “mamapreneurs” are addressing the care needs in their communities</h2>

				<div className="two-column">
					<div className="text-column">
						<p>The child care crisis is hitting economies and societies around the world hard. With more than 350 million young children unable to receive child care globally, all too many parents are forced to choose between working to put food on the table and providing quality care for their children. Low-cost child care options frequently undernourish children, both physically and mentally, at a critical stage in their development, with wide-ranging implications for their ability to achieve their true potential. What’s more, it’s estimated that solving the world’s caregiving crisis and enabling parents to return to the workforce could unlock up to $3 trillion in global GDP annually. As countries seek to pull themselves out of the pandemic’s economic downturn, the price of ignoring the caregiving crisis is becoming increasingly unaffordable.</p> 
 					</div>
					<div className="Report_Download">
						<h4>Tackling the Care Crisis to Unleash Women’s Economic Potential</h4> 
						<h5>Economic impacts and policy recommendations to ensure women are not left behind</h5>
						<img src={illustration_image_6} alt="Tackling the Care Crisis to Unleash Women’s Economic Potential" />
						<p>Women globally experience greater economic insecurity, largely because of gender roles that consider caregiving “women’s work.” Such work, while essential to sustaining economic production, is undervalued and most often unpaid or underpaid. This structural division not only exploits women but also limits women’s participation in the formal labor force, suppressing economic growth worldwide. A summary of the estimated impacts of the care crisis and recommendations for action can be found here:</p> 
						<p className="download_button"><a href="/caregiving-impact-recommendations.pdf" download>Download the pdf</a></p>
					</div>
				</div>

				<h3 className="header-h3-sans">Sabrina’s Story: A Kenyan Solution to the Child Care Crisis</h3> 

				<video poster="/video-placeholder.jpg" controls>
					<source src="/fpanalytics-gates-480p.mov" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				 
				<p>Fortunately, emerging solutions to the child care crisis are beginning to take root. In Kenya, Kidogo—an innovative social franchise created by CEO and co-founder Sabrina Habib—is matching Kenyan entrepreneurs with families who are in need of early child care. These “mamapreneurs” are then able to earn a living providing high-quality care for their own and their neighbors’ children for about the same price as other low-cost options, around $1 per child per day. Take a look behind the scenes of Kenyan child care, and step into a typical day at Kidogo through this photo essay by Tobin Jones. </p> 
				
				<h3 className="header-h3-sans">What Caregiving Looks Like on the Ground: A Photo Essay</h3>
				<SlickSliderCaregiving color="#ffffff" />
				 
				<div className="Podcast_Caregiving">
                    <div className="podcast_image_container">
                        <a href="https://foreignpolicy.com/podcasts/hidden-economics-of-remarkable-women-hero/" target="_blank" rel="noreferrer">
                            <img src={podcast_icon} alt= "Listen to the HERO Podcast" />
                        </a>
                    </div>
                    <div>
                    	<h4>Podcast: The Hidden Economics of Remarkable Women (HERO)</h4>
                    	<p>Habib joined FP podcast host Reena Ninan for the premiere episode of the show, Hidden Economics of Remarkable Women (or HER♀ for short), where she shared her experience with launching a franchise model for affordable child care centers in Kenya. Learn more about Habib’s journey and gain insights on early childhood development in Kenya from renowned research scientist Patricia Wekulo by listening to episode 1 here.</p>
                    	<audio controls="controls" preload="none" id="html5_audio_mjbumeb2ptc">
							<source src="https://traffic.megaphone.fm/SLT1425135631.mp3?updated=1624909495" type="audio/mp3" />
							<object type="application/x-shockwave-flash" data="http://releases.flowplayer.org/swf/flowplayer-3.2.1.swf" width="640" height="360">
								<param name="audio" value="http://releases.flowplayer.org/swf/flowplayer-3.2.1.swf" />
								<param name="allowFullScreen" value="true" /> 
								<param name="wmode" value="transparent" />
								<param name="flashVars" value="config={'playlist':[{'url':'http://https%3A%2F%2Ftraffic.megaphone.fm%2FSLT1425135631.mp3%3Fupdated%3D1624909495','autoPlay':false}]}" />
							</object>
						</audio>
                    </div>
                </div>

				<p><i>By FP Studios and FP Analytics, the research and advisory division of Foreign Policy.</i></p>
				<p><i>With support from the Bill & Melinda Gates Foundation</i></p>

				<div className="row">
					<div className="col-12">
						<p className="Logo_Container">
							<img src={fp_studios_logo} alt="FP Studios Logo" style={{maxWidth: "90%", width: "170px", marginBottom: "2rem", marginRight: "2rem"}} />
							<img src={fp_logo} alt="Foreign Policy Analytics Logo" style={{maxWidth: "90%", width: "100px", marginBottom: "2rem", marginRight: "2rem"}} />
							<img src={gates_logo} alt="Bill and Melinda Gates Foundation Logo" style={{maxWidth: "90%", width: "325px", marginBottom: "2rem"}} />
						</p>
					</div>
				</div>
			</div>
				
		);
	}
}

export default Caregiving;
