import React from 'react';
import styles from './IllustrationBox.module.scss';

class IllustrationBox extends React.Component {
	goToNext() {
		const id = this.props.id;
		const newId = parseInt(id.replace(/illustration-/, '')) + 1;
		window.location = '#illustration-' + newId;
	}

	render() {
		const slopeClass = 'up' === this.props.slopeStyle ? styles.UpSlope : styles.DownSlope;
		const showImage  = !!this.props.image;
		
		return (
			<>
				<div id={this.props.id} />
				<div className={styles.IllustrationBox}>
					
					<div 
						className={styles.TextContent + ' ' + slopeClass} 
						style={{
							width: this.props.textWidth,
							backgroundColor: this.props.textBgColor,
							borderBottom: `1px solid ${this.props.textBgColor}`,
							borderTop: `1px solid ${this.props.textBgColor}`,
							color: this.props.textColor,
							paddingBottom: this.props.paddingBottom,
						}}
					>
						{this.props.title &&
						<h2>{this.props.title}</h2>
						}

						{showImage && 
							<div className={styles.Image}>
								<div className="show-for-desktop"><img src={this.props.image} alt={this.props.imageAlt} /></div>
								<div className="show-for-mobile"><img src={this.props.mobileImage} alt={this.props.imageAlt} /></div>
							</div>
						}

						{this.props.text && 
							<p 
								className={styles.Text} 
								dangerouslySetInnerHTML={{__html:this.props.text}}>
							</p>
						}
					</div>
				</div>
			</>
		);
	}
}

export default IllustrationBox;
