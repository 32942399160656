import React from 'react';
import styles from './ExpandableSection.module.scss';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

class ExpandableSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
		this.myRef = React.createRef();
	}

	toggleContents(e) {
		if(this.props.onToggle) {
			this.props.onToggle(e);
		}

		this.setState((state, props) => ({
			isOpen: !state.isOpen
		}));

		return true;
	}

	navigateToTop() {
		this.myRef.current.scrollIntoView();
	}

	render() {
		let titleBackground = !this.state.isOpen ? this.props.titleBackground : "#ffffff";

		return (
			<div className={styles.ExpandableSection} ref={this.myRef} style={{ padding: this.props.padding }}>
				<button onClick={(e) => this.toggleContents(e)}>
					{this.props.color && 
						<div className={styles.LeftRect} style={{ backgroundColor: this.props.color }}></div>
					}

					<h2 className={styles.Title} style={{backgroundColor: titleBackground}}>
						{this.props.title}
					</h2>

					<span className={styles.Icons} style={{backgroundColor: titleBackground}}>
						{!this.state.isOpen && <AddCircleOutlineIcon className={styles.Icon} style={{ backgroundColor: this.props.color, fill: this.props.color && '#fff' }} />}
						{this.state.isOpen && <HighlightOffIcon className={styles.Icon} style={{ backgroundColor: this.props.color, fill: this.props.color && '#fff' }} />}
					</span>
				</button>

				{this.state.isOpen && 
					<div className={styles.Content}>
						{this.props.children}

						<HighlightOffIcon 
							className={styles.Icon + ' ' + styles.IconCloseBottom}
							style={{ backgroundColor: this.props.color, fill: this.props.color && '#fff' }}
							onClick={() => this.toggleContents() && this.navigateToTop()}
						/>
					</div>
				}
			</div>
		);
	}
}

export default ExpandableSection;
