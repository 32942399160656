import React from 'react';
import FullWidthSection from '../FullWidthSection/FullWidthSection.js';
import ContainedContent from '../FullWidthSection/ContainedContent.js';

import styles from './References.module.scss';

class ExecutiveSummary extends React.Component {
	render() {
		return (
			<FullWidthSection className={styles.References} style={this.props.style}>
				<ContainedContent>
					<h2 className="header-h2">References</h2>
					<ul className="font-weight-500">
						<li>A Feminist Recovery Plan for Canada. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.feministrecovery.ca/">https://www.feministrecovery.ca/</a></li>
						<li>Acciari, L., del Carmen Britez, J., & del Carmen Morales Perez, A. (2021). Right to Health, Right to Live: Domestic Workers Facing the COVID-19 Crisis in Latin America. Gender and Development, Vol. 29 (1), 11–33. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.tandfonline.com/doi/full/10.1080/13552074.2021.1885213">https://www.tandfonline.com/doi/full/10.1080/13552074.2021.1885213</a></li>
						<li>Actionaid. (2021). Unpaid Care and Domestic Work. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.actionaid.org.uk/our-work/womens-economic-rights/unpaid-care-and-domestic-work">https://www.actionaid.org.uk/our-work/womens-economic-rights/unpaid-care-and-domestic-work</a></li>
						<li>Allen, B. (2020). How SILCs Have Responded to the COVID-19 Pandemic: Learning from Surveys in Latin America and Africa. Retrieved from <a target="_blank" rel="noreferrer" href="https://mangotree.org/files/galleries/CRS_Covid-19_Survey-Report_FINAL.pdf">https://mangotree.org/files/galleries/CRS_Covid-19_Survey-Report_FINAL.pdf</a></li>
						<li>Ayres, A., Nanavaty, R. (2020). India: How Will Women in the Informal Economy Get By? Council on Foreign Relations. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.cfr.org/blog/india-how-will-women-informal-economy-get">https://www.cfr.org/blog/india-how-will-women-informal-economy-get</a></li>
						<li>Azcona, G., Bhatt, A., & Love, K. (2020). The COVID-19 Pandemic Has Increased the Care Burden, but by How Much? UN Women. Retrieved from <a target="_blank" rel="noreferrer" href="https://data.unwomen.org/features/covid-19-pandemic-has-increased-care-burden-how-much-0">https://data.unwomen.org/features/covid-19-pandemic-has-increased-care-burden-how-much-0</a></li>
						<li>Azcona, G., Bhatt, A., Encarnacion, J., Plazaola-Castano, J., Seck, P., Staab, S., & Turquet, L. (2020). From Insights to Action: Gender Equality in the Wake of COVID-19. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/gender-equality-in-the-wake-of-covid-19-en.pdf?la=en&vs=5142">https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/gender-equality-in-the-wake-of-covid-19-en.pdf?la=en&vs=5142</a></li>
						<li>Badiee, S., Swanson, E., Noe, L., Wahabzada, T., Pittman, A., & Appel, D. (2021). State of Gender Data Financing 2021. Data2x. Retrieved from <a target="_blank" rel="noreferrer" href="https://data2x.org/wp-content/uploads/2021/05/State-of-Gender-Data-Financing-2021_FINAL.pdf">https://data2x.org/wp-content/uploads/2021/05/State-of-Gender-Data-Financing-2021_FINAL.pdf</a></li>
						<li>Banerjee, M. (2021). Emerging From Lockdown: Insights From Women Waste Workers’ Lives in Delhi. ISST. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.isstindia.org/publications/1610690169_pub_Final_Designed_Waste_Workers_Report.pdf">https://www.isstindia.org/publications/1610690169_pub_Final_Designed_Waste_Workers_Report.pdf</a></li>
						<li>Bargotra, N., Bhatotia, K., Karthick, M.P., and Narasimhan, M. (May 2021). “How Did India’s Women Enterprises Fare During the COVID-19 Lockdown?” Economic and Political Weekly – Engage. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.epw.in/engage/article/how-did-indias-women-enterprises-fare-during-covid">https://www.epw.in/engage/article/how-did-indias-women-enterprises-fare-during-covid</a></li>
						<li>Boniol, M., McIsaac, M., Xu, L., Wuliji, T., Dialo, K., & Campbell, J. (2019). Gender Equity in the Health Workforce: Analysis of 104 Countries. WHO. Retrieved from <a target="_blank" rel="noreferrer" href="https://apps.who.int/iris/bitstream/handle/10665/311314/WHO-HIS-HWF-Gender-WP1-2019.1-eng.pdf?ua=1">https://apps.who.int/iris/bitstream/handle/10665/311314/WHO-HIS-HWF-Gender-WP1-2019.1-eng.pdf?ua=1</a></li>
						<li>Bruhn, M., Hommes, M., Khanna, M., Singh, S., Sorokina, A., & Wimpey, J. (2017). MSME Finance Gap: Assessment of the Shortfalls and Opportunities in Financing Micro, Small, and Medium Enterprises in Emerging Markets. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ifc.org/wps/wcm/connect/03522e90-a13d-4a02-87cd-9ee9a297b311/121264-WP-PUBLIC-MSMEReportFINAL.pdf?MOD=AJPERES&CVID=m5SwAQA">https://www.ifc.org/wps/wcm/connect/03522e90-a13d-4a02-87cd-9ee9a297b311/121264-WP-PUBLIC-MSMEReportFINAL.pdf?MOD=AJPERES&CVID=m5SwAQA</a></li>
						<li>Buvinic, M., King, E. (2018). Invisible No More? A Methodology and Policy Review of How Time Use Surveys Measure Unpaid Work. Data2x. Retrieved from <a target="_blank" rel="noreferrer" href="https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf">https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf</a></li>
						<li>Buvinic, M., Noe, L., & Swanson, E. (2020). Understanding Women’s and Girls’ Vulnerabilities to the COVID-19 Pandemic: A Gender Analysis and Data Dashboard of Low-and Lower-Middle Income Countries. Retrieved from <a target="_blank" rel="noreferrer" href="https://data2x.org/wp-content/uploads/2020/11/COVID-19-Vulnerability-Paper_FINAL-2.pdf">https://data2x.org/wp-content/uploads/2020/11/COVID-19-Vulnerability-Paper_FINAL-2.pdf</a></li>
						<li>CARE. (2020d). Coping with COVID-19: The Experience of Savings Groups in Nigeria. Retrieved from <a target="_blank" rel="noreferrer" href="https://care.org/wp-content/uploads/2020/11/Coping-With-COVID_Nigeria.pdf">https://care.org/wp-content/uploads/2020/11/Coping-With-COVID_Nigeria.pdf</a></li>
						<li>Chakma, T. (2020). Impact of COVID-19 on Young Women: A Rapid Assessment of 14 Urban Areas in India, Kenya, Ghana, and South Africa. ActionAid. Retrieved from <a target="_blank" rel="noreferrer" href="https://actionaid.org/publications/2020/impact-covid-19-young-women">https://actionaid.org/publications/2020/impact-covid-19-young-women</a></li>
						<li>Chatterjee, M. (2020). SEWA Cooperate Federation’s Role in COVID-19 Response and Beyond. SEWA Cooperative Federation. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/---emp_ent/---coop/documents/presentation/wcms_743025.pdf">https://www.ilo.org/wcmsp5/groups/public/---ed_emp/---emp_ent/---coop/documents/presentation/wcms_743025.pdf</a></li>
						<li>Chikarmane, P. (2012). Integrating Waste Pickers Into Municipal Solid Waste Management in Pune, India. WIEGO. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.wiego.org/sites/default/files/publications/files/Chikarmane_WIEGO_PB8.pdf">https://www.wiego.org/sites/default/files/publications/files/Chikarmane_WIEGO_PB8.pdf</a></li>
						<li>Chopra, D., Sempere, K., & Krishnan, M. (2021). Assessing Unpaid Care Work: A Participatory Toolkit. Institute of Development Studies. Retrieved from <a target="_blank" rel="noreferrer" href="https://opendocs.ids.ac.uk/opendocs/bitstream/handle/20.500.12413/16439/Assessing_Unpaid_Care_Work_A_Participatory_Toolkit.pdf?sequence=1&isAllowed=y">https://opendocs.ids.ac.uk/opendocs/bitstream/handle/20.500.12413/16439/Assessing_Unpaid_Care_Work_A_Participatory_Toolkit.pdf?sequence=1&isAllowed=y</a></li>
						<li>Choudhury, P. R., Behera, M.K., Sharma, S., & Haque, T. (2017). Administrative and Open Source Data for Monitoring Land Governance: Mapping Women Land Rights in the Context of UN’s SDG in India. The World Bank. Retrieved from <a target="_blank" rel="noreferrer" href="https://landportal.org/library/resources/administrative-and-open-source-data-monitoring-land-governance-mapping-women-land">https://landportal.org/library/resources/administrative-and-open-source-data-monitoring-land-governance-mapping-women-land</a></li>
						<li>Coffey, C., Revollo, P., Harvey, R., Lawson, M., Butt, A., Piaget, K., Sarosi, D., & Thekkudan, J. (2020). Time to Care: Unpaid and Underpaid Care Work and the Global Inequality Crisis. OXFAM. Retrieved from <a target="_blank" rel="noreferrer" href="https://oxfamilibrary.openrepository.com/bitstream/handle/10546/620928/bp-time-to-care-inequality-200120-en.pdf?sequence=36&isAllowed=y">https://oxfamilibrary.openrepository.com/bitstream/handle/10546/620928/bp-time-to-care-inequality-200120-en.pdf?sequence=36&isAllowed=y</a></li>
						<li>Cucagna, M.E., Romero, J. (2021). The Gendered Impacts of COVID-19 on the Labor Market: Evidence From High-Frequency Phone Surveys in Latin America and the Caribbean (English). Gender Innovation Lab policy brief, World Bank Group. Retrieved from <a target="_blank" rel="noreferrer" href="https://documents.worldbank.org/curated/en/675641612934705667/The-Gendered-Impacts-of-COVID-19-on-the-Labor-Market-Evidence-from-High-Frequency-Phone-Surveys-in-Latin-America-and-the-Caribbean">https://documents.worldbank.org/curated/en/675641612934705667/The-Gendered-Impacts-of-COVID-19-on-the-Labor-Market-Evidence-from-High-Frequency-Phone-Surveys-in-Latin-America-and-the-Caribbean</a></li>
						<li>Dalberg Advisors. (2020). Impact of COVID-19 on Women in Low-Income Households in India. Retrieved from <a target="_blank" rel="noreferrer" href="https://sewabharatresearch.org/wp-content/uploads/2020/10/Protection-of-Livelihoods-SEWA-Data-September-2020.pdf">https://sewabharatresearch.org/wp-content/uploads/2020/10/Protection-of-Livelihoods-SEWA-Data-September-2020.pdf</a></li>
						<li>Data for Good. (2020). Global State of Small Business Report. Retrieved from <a target="_blank" rel="noreferrer" href="https://dataforgood.fb.com/wp-content/uploads/2020/07/GlobalStateofSmallBusinessReport.pdf">https://dataforgood.fb.com/wp-content/uploads/2020/07/GlobalStateofSmallBusinessReport.pdf</a></li>
						<li>De Henau, J. & Perrons, D. (2016). Investing in the Care Economy to Boost Employment and Gender Equality. UK Women’s Budget Group. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.lse.ac.uk/gender/assets/documents/news/Investing-in-the-Care-economy.pdf">https://www.lse.ac.uk/gender/assets/documents/news/Investing-in-the-Care-economy.pdf</a></li>
						<li>De Henau, J., Himmelweit, S. (2021). A Care-Led Recovery from COVID-19: Investing in High-Quality Care to Stimulate and Rebalance the Economy. Feminist Economics, Vol. 27 (1-2), 453-69. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.tandfonline.com/doi/full/10.1080/13545701.2020.1845390">https://www.tandfonline.com/doi/full/10.1080/13545701.2020.1845390</a></li>
						<li>De Hoop, T., Holla, C., Siwach, G., Jayaram, S.,  Anderson, L., Walcott, R., Kapoor, S., Ammu Sanyal, K., Tankha, R., Valenti, F. Desai, S., Jafa, K. (2021, forthcoming). Evidence Review of Self-Help Groups and COVID-19: Effects on and Challenges for the National Rural Livelihoods Mission in India.</li>
						<li>Department of Economic and Social Affairs Statistics Division. (2018). Demographic and Social Statistics. Retrieved from <a target="_blank" rel="noreferrer" href="https://unstats.un.org/unsd/demographic-social/time-use/time-use-expert-group/#ws">https://unstats.un.org/unsd/demographic-social/time-use/time-use-expert-group/#ws</a></li>
						<li>Department of Economic and Social Affairs. (2016). Integrating a Gender Perspective into Statistics. Retrieved from <a target="_blank" rel="noreferrer" href="https://unstats.un.org/unsd/demographic-social/Standards-and-Methods/files/Handbooks/gender/Integrating-a-Gender-Perspective-into-Statistics-E.pdf">https://unstats.un.org/unsd/demographic-social/Standards-and-Methods/files/Handbooks/gender/Integrating-a-Gender-Perspective-into-Statistics-E.pdf</a></li>
						<li>Department of Finance Canada. Government of Canada. (2021). Budget 2021: Supporting Women. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.canada.ca/en/department-finance/news/2021/04/budget-2021-supporting-women.html">https://www.canada.ca/en/department-finance/news/2021/04/budget-2021-supporting-women.html</a></li>
						<li>Deshpande, A. (2020). The COVID-19 Pandemic and Gendered Division of Paid and Unpaid Work: Evidence from India. IZA Discussion Paper No. 13815. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.iza.org/publications/dp/13815/the-covid-19-pandemic-and-gendered-division-of-paid-and-unpaid-work-evidence-from-india">https://www.iza.org/publications/dp/13815/the-covid-19-pandemic-and-gendered-division-of-paid-and-unpaid-work-evidence-from-india</a></li>
						<li>Dugarova, E. (2020). Unpaid Care Work in Times of the COVID-19 Crisis: Gendered Impacts, Emerging Evidence and Promising Policy Responses. Working Paper. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.un.org/development/desa/family/wp-content/uploads/sites/23/2020/09/Duragova.Paper_.pdf">https://www.un.org/development/desa/family/wp-content/uploads/sites/23/2020/09/Duragova.Paper_.pdf</a></li>
						<li>Fresnillo, I. (2020). Out of Service: How Public Services and Human Rights are Being Threatened by the Growing Debt Crisis. Citizens for Financial Justice. Retrieved from <a target="_blank" rel="noreferrer" href="https://d3n8a8pro7vhmx.cloudfront.net/eurodad/pages/533/attachments/original/1590692297/Out_of_service.pdf?1590692297">https://d3n8a8pro7vhmx.cloudfront.net/eurodad/pages/533/attachments/original/1590692297/Out_of_service.pdf?1590692297</a></li>
						<li>Garz, S., Heath, R., Kipchumba, E. & Sulaiman, M. (2020). Evidence of Digital Financial Services Impacting Women’s Economic Empowerment. BRAC Institute of Governance and Development. Retrieved from <a target="_blank" rel="noreferrer" href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf">https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf</a></li>
						<li>Government of the Netherlands. (2017). Netherlands Invests in Boosting Women’s Organisations in Southern Countries. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.government.nl/latest/news/2017/02/09/netherlands-invests-in-boosting-women%E2%80%99s-organisations-in-southern-countries">https://www.government.nl/latest/news/2017/02/09/netherlands-invests-in-boosting-women%E2%80%99s-organisations-in-southern-countries</a></li>
						<li>Grantham, K., Rouhani, L., Gupta, N., Melesse, M., Dhar, D., Kapoor Mehta, S., & Jha Kingra, K. (2021). Evidence Review of the Global Childcare Crisis and the Road for Post-COVID-19 Recovery and Resilience. IDRC. Retrieved from <a target="_blank" rel="noreferrer" href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf">https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf</a></li>
						<li>Grantham, K., Rouhani, L., Gupta, N., Melesse, M., Dhar, D., Mehta, S., & Kingra, K. (2020). Evidence Review of the Global Childcare Crisis and the Road for Post-Covid-19 Recover and Resilience. Retrieved from <a target="_blank" rel="noreferrer" href="https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf">https://docs.gatesfoundation.org/documents/evidence_review_of_the_global_childcare_crisis_and_the_road_ahead_for_covid-19_recovery_and_resilience_english.pdf</a></li>
						<li>Hai-Anh, D., Viet Nguyen, C. (2021). Gender Inequality During the COVID-19 Pandemic: Income, Expenditure, Savings, and Job Loss. World Development 140. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.iza.org/publications/dp/13824/gender-inequality-during-the-covid-19-pandemic-income-expenditure-savings-and-job-loss">https://www.iza.org/publications/dp/13824/gender-inequality-during-the-covid-19-pandemic-income-expenditure-savings-and-job-loss</a></li>
						<li>Hardy, M., Gisella Kagy, Erin., & McCasland, J.  (2021). Resilience of the Informal Labor Market: Evidence from COVID-19 Pandemic in Ghana. Working Paper.</li>
						<li>Hay, K., McDougal, L., Percival, V., Henry, S., Klugman, J., Wurie, H., Raven, J., Shabalala, F., Fielding-Miller, R., Dey, A., Dehingia, N., Morgan, R., Atmavilas, Y., Saggurti, N., et al. (2019). Disrupting Gender Norms in Health Systems: Making the Case for Change. The Lancet, Vol. 393 (10190), 2535–49. Retrieved from <a target="_blank" rel="noreferrer" href="https://doi.org/10.1016/S0140-6736(19)30648-8">https://doi.org/10.1016/S0140-6736(19)30648-8</a></li>
						<li>Heintz, J., Staab, S., & Turquet, L. (2021). Don’t Let Another Crisis Go to Waste: The COVID-19 Pandemic and the Imperative for a Paradigm Shift. Feminist Economics, Vol. 27 (1-2), 470-85. Retrieved from  <a target="_blank" rel="noreferrer" href="https://doi.org/10.1080/13545701.2020.1867762">https://doi.org/10.1080/13545701.2020.1867762</a></li>
						<li>Human Rights Council, UN General Assembly. (2018). Guiding Principles on Human Rights Impact Assessments of Economic Reforms. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.undocs.org/A/HRC/40/57">https://www.undocs.org/A/HRC/40/57</a></li>
						<li>ILO Monitor. (2021). COVID-19 and the World of Work. ILO. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/@dgreports/@dcomm/documents/briefingnote/wcms_767028.pdf">https://www.ilo.org/wcmsp5/groups/public/@dgreports/@dcomm/documents/briefingnote/wcms_767028.pdf</a></li>
						<li>ILO. (2018). Women and Men in the Informal Economy: A Statistical Picture. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf">https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf</a></li>
						<li>International Domestic Workers Federation Recommendations. Retrieved from <a target="_blank" rel="noreferrer" href="https://idwfed.org/en/covid-19/the-idwf/advocacy/idwf-recommendations.pdf">https://idwfed.org/en/covid-19/the-idwf/advocacy/idwf-recommendations.pdf</a></li>
						<li>International Domestic Workers Federation. (2020). Global: IDWF Statement on Protecting Domestic Workers Rights and Fighting the Coronavirus Pandemic. Retrieved from <a target="_blank" rel="noreferrer" href="https://idwfed.org/en/updates/global-idwf-statement-on-protecting-domestic-workers-rights-and-fighting-the-coronavirus-pandemic">https://idwfed.org/en/updates/global-idwf-statement-on-protecting-domestic-workers-rights-and-fighting-the-coronavirus-pandemic</a></li>
						<li>International Labour Organization. (2011). C189-Domestic Workers Convention, 2011 (No.189). Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C189">https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C189</a></li>
						<li>International Labour Organization. (2016). Policies to Formalize Paid Domestic Work in Latin America and the Caribbean. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---americas/---ro-lima/documents/publication/wcms_534457.pdf">https://www.ilo.org/wcmsp5/groups/public/---americas/---ro-lima/documents/publication/wcms_534457.pdf</a></li>
						<li>International Labour Organization. (2018). Care Work and Care Jobs for the Future of Decent Work. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/global/publications/books/WCMS_633135/lang--en/index.htm">https://www.ilo.org/global/publications/books/WCMS_633135/lang--en/index.htm</a></li>
						<li>International Labour Organization. (2018). Women and Men in the Informal Economy: A Statistical Picture. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf">https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_626831.pdf</a></li>
						<li>International Labour Organization. (2020). A Gender-Responsive Employment Recovery: Building Back Fairer. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---ed_emp/documents/publication/wcms_751785.pdf">https://www.ilo.org/wcmsp5/groups/public/---ed_emp/documents/publication/wcms_751785.pdf</a></li>
						<li>International Labour Organization. (2020). Impact of the COVID-19 Crisis on Loss of Jobs and Hours Among Domestic Workers. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---travail/documents/publication/wcms_747961.pdf">https://www.ilo.org/wcmsp5/groups/public/---ed_protect/---protrav/---travail/documents/publication/wcms_747961.pdf</a></li>
						<li>Jabola-Carolus, K. (2020). Building Bridges: Not Walking on Backs. Hawai’i State Commission on the Status of Women Department of Human Services State of Hawai’i. Retrieved from <a target="_blank" rel="noreferrer" href="https://humanservices.hawaii.gov/wp-content/uploads/2020/04/4.13.20-Final-Cover-D2-Feminist-Economic-Recovery-D1.pdf">https://humanservices.hawaii.gov/wp-content/uploads/2020/04/4.13.20-Final-Cover-D2-Feminist-Economic-Recovery-D1.pdf</a></li>
						<li>Janoch, E. (2020). Rapid Gender Analysis: Filing the Data Gap to Build Back Equal. CARE. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.care-international.org/files/files/RGA_SheToldUsSo_9_18_20.pdf">https://www.care-international.org/files/files/RGA_SheToldUsSo_9_18_20.pdf</a></li>
						<li>Joint Working Group on Pay Equity Principles. (2020). Equal Pay Amendment Act. New Zealand Government. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.mbie.govt.nz/business-and-employment/employment-and-skills/employment-legislation-reviews/equal-pay-amendment-act/">https://www.mbie.govt.nz/business-and-employment/employment-and-skills/employment-legislation-reviews/equal-pay-amendment-act/</a></li>
						<li>Kabeer, N., Razavi, S., & Meulen Rodgers, Y. V. D. (2021). Feminist Economic Perspectives on the COVID-19 Pandemic. Feminist Economics, Vol. 27 (1-2), 1–29. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.tandfonline.com/doi/full/10.1080/13545701.2021.1876906">https://www.tandfonline.com/doi/full/10.1080/13545701.2021.1876906</a></li>
						<li>Kevane, M., Aishwarya Lakshmi, R., & Dhar, D. (2021). Women-Led Small and Medium Enterprises (SME) Before, During, and After COVID-19. Working Paper.</li>
						<li>Khan, Z., Gifford, K. (2021). COVID-10 and Fiscal Policy: Applying Gender-Responsive Budgeting in Support and Recovery Measures. UN Women. Policy Brief No. 21. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2021/policy-brief-covid-19-and-fiscal-policy-en.pdf?la=en&vs=3544">https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2021/policy-brief-covid-19-and-fiscal-policy-en.pdf?la=en&vs=3544</a></li>
						<li>King, T., Hewitt, B., Crammond, B., Sutherland, G., Maheen, H., & Kavanagh, A. (2020). Reordering Gender Systems: Can COVID-19 Lead to Improved Gender Equality and Health? The Lancet, Vol. 396, 1-2. Retrieved from <a target="_blank" rel="noreferrer" href="https://doi.org/10.1016/S0140-6736(20)31418-5">https://doi.org/10.1016/S0140-6736(20)31418-5</a></li>
						<li>Lakshmi Ratan, A., Roever S., Jhabvala, R., & Sen, P. (2021). COVID-19 and Women’s Informal Employment: A Call to Support the Most Vulnerable First in the Economic Recovery. Working Policy Paper.</li>
						<li>Malala Fund. (2020). Girls’ Education and COVID-19: What Past Shocks Can Teach Us About Mitigating the Impact of Pandemics. Retrieved from <a target="_blank" rel="noreferrer" href="https://downloads.ctfassets.net/0oan5gk9rgbh/6TMYLYAcUpjhQpXLDgmdIa/3e1c12d8d827985ef2b4e815a3a6da1f/COVID19_GirlsEducation_corrected_071420.pdf">https://downloads.ctfassets.net/0oan5gk9rgbh/6TMYLYAcUpjhQpXLDgmdIa/3e1c12d8d827985ef2b4e815a3a6da1f/COVID19_GirlsEducation_corrected_071420.pdf</a></li>
						<li>McDougal, L., Raj, A., Yore, J., Boyd, S., et al. (2020). Strengthening Gender Measures and Data in the COVID-19 Era: An Urgent Need for Change. Retrieved from <a target="_blank" rel="noreferrer" href="https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf">https://docs.gatesfoundation.org/Documents/COVID-19_Gender_Data_and_Measures_Evidence_Review.pdf</a></li>
						<li>Meakin, L., Diamond, J. (2020, April 3). Coronavirus Pauses U.K. Gender Pay Gap Reporting Ahead of April Deadline. Bloomberg. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.bloomberg.com/news/articles/2020-04-03/britain-s-gender-pay-reporting-is-paused-because-of-pandemic">https://www.bloomberg.com/news/articles/2020-04-03/britain-s-gender-pay-reporting-is-paused-because-of-pandemic</a></li>
						<li>Miller, A. R., Calder, R., & Boost, E. (2020). Impacts of COVID-19 on Childcare in East Africa. Bill & Melinda Gates Foundation Briefing Note.</li>
						<li>Ministry of Rural Development (MoRD). (2020e). COVID-19 Response by SHG Women Warriors. Retrieved from <a target="_blank" rel="noreferrer" href="https://aajeevika.gov.in/sites/default/files/nrlp_repository/COVID-19%20Response%20by%20Women%20SHG%20Warriors.pdf">https://aajeevika.gov.in/sites/default/files/nrlp_repository/COVID-19%20Response%20by%20Women%20SHG%20Warriors.pdf</a></li>
						<li>Morgan, R., Ayiasi, R., Barman, D., Buzuzi, S., Ssemugabo, C., Ezumah, N., George, A., Hawkins, K., Hao, X., King, R., Liu, T., Molyneux, S., Muraya, K., Musoke, D., Nyamhanga, T., Ros, B., Tani, K., Theobald, S., Vong, S., & Waldman, L. (2018). Gendered Health Systems: Evidence from Low-and Middle-Income Countries. Health Research Policy and Systems, Vol. 16 (58). Retrieved from <a target="_blank" rel="noreferrer" href="https://doi.org/10.1186/s12961-018-0338-5">https://doi.org/10.1186/s12961-018-0338-5</a></li>
						<li>Moussie, R., Staab, S. (2020). Three Ways to Contain COVID-19’s Impact on Informal Women Workers. UN Women. Retrieved from <a target="_blank" rel="noreferrer" href="https://data.unwomen.org/features/three-ways-contain-covid-19s-impact-informal-women-workers">https://data.unwomen.org/features/three-ways-contain-covid-19s-impact-informal-women-workers</a></li>
						<li>Namisango, E., de Hoop, T., Holla, C., Siwach, G., Chidiac, S., Jayaram, S., Grzeslo, J., Sulaiman, M., Janoch, E., Majara, G., et al. (2021). Evidence Review of Women’s Groups and COVID-19: Impacts, Challenges, and Policy Implications for Savings Groups in Africa. Retrieved from <a target="_blank" rel="noreferrer" href="https://docs.gatesfoundation.org/documents/evidence_review_of_womens_groups_and_covid19_impacts_challenges_and_policy_implications_for_savings_groups_in_africa.pdf">https://docs.gatesfoundation.org/documents/evidence_review_of_womens_groups_and_covid19_impacts_challenges_and_policy_implications_for_savings_groups_in_africa.pdf</a></li>
						<li>Narasimhan, M., Morchan, K., Bargotra, N., Wangchuk, R., Gupta, A., & Pinto, A. (2020). COVID-19 Enterprise Response Research. LEAD at Krea University.</li>
						<li>Nefesh-Clarke, L., Orser, B., & Thomas, M. (2020). COVID-19 Response Strategies, Addressing Digital Gender Divides. G20 2020. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.g20-insights.org/policy_briefs/covid-19-response-strategies-addressing-digital-gender-divides/#_edn14">https://www.g20-insights.org/policy_briefs/covid-19-response-strategies-addressing-digital-gender-divides/#_edn14</a></li>
						<li>O’Donnell, M. (2020). From Principles to Practice: Strengthening Accountability for Gender Equality in International Development. Center for Global Development. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.cgdev.org/sites/default/files/from-priciples-practice-gender-equality.pdf">https://www.cgdev.org/sites/default/files/from-priciples-practice-gender-equality.pdf</a></li>
						<li>O’Donnell, M., Buvinic, M., Bourgault, S., & Webster, B. (2021). The Gendered Dimensions of Social Protection in the COVID-19 Context. Working Paper. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.cgdev.org/publication/gendered-dimensions-social-protection-covid-19-context">https://www.cgdev.org/publication/gendered-dimensions-social-protection-covid-19-context</a></li>
						<li>O’Donnell, M., Buvinic, M., Krubiner, C., & Bourgault, Shelby. (2021). The Impacts of Health Crises on Women & Girls: How Historical Evidence Can Inform Assessment and Recovery Through a Gender Lens. Working Paper. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.cgdev.org/sites/default/files/impacts-of-health-crises-women-and-girls.pdf">https://www.cgdev.org/sites/default/files/impacts-of-health-crises-women-and-girls.pdf</a></li>
						<li>OECD. (2020). Women at the Core of the Fight Against COVID-19 Crisis. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/#section-d1e624">https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/#section-d1e624</a></li>
						<li>Onofrio, J., Ibrahim-Leathers, H. (2020). Covid-19 and Widowhood: The Virus’ Invisible Victim. Global Fund for Widows. Retrieved from <a target="_blank" rel="noreferrer" href="https://uploads-ssl.webflow.com/5fce889a3c0f6e35f56692ce/5fce889a3c0f6e0e0f669306_COVID-and-Widowhood-MAY-2020.pdf">https://uploads-ssl.webflow.com/5fce889a3c0f6e35f56692ce/5fce889a3c0f6e0e0f669306_COVID-and-Widowhood-MAY-2020.pdf</a></li>
						<li>Organisation for Economic Cooperation and Development. (2020). Women at the Core of the Fight Against COVID-19 Crisis. OCED Policy Responses to COVID-19. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/">https://www.oecd.org/coronavirus/policy-responses/women-at-the-core-of-the-fight-against-covid-19-crisis-553a8269/</a></li>
						<li>Oxfam International. (2020). The IMF Must Immediately Stop Promoting Austerity around the World. Medium. Retrieved from <a target="_blank" rel="noreferrer" href="https://oxfam.medium.com/the-imf-must-immediately-stop-promoting-austerity-around-the-world-49a8d7ba7152">https://oxfam.medium.com/the-imf-must-immediately-stop-promoting-austerity-around-the-world-49a8d7ba7152</a></li>
						<li>Palladino, L., Gunn-Wright, R. (2021). Care and Climate: Understanding the Policy Intersections. A Feminist Green New Deal Coalition Brief. Retrieved from <a target="_blank" rel="noreferrer" href="http://feministgreennewdeal.com/wp-content/uploads/2021/04/FemGND-IssueBrief-Draft7-Apr15.pdf">http://feministgreennewdeal.com/wp-content/uploads/2021/04/FemGND-IssueBrief-Draft7-Apr15.pdf</a></li>
						<li>Pande, R., Schaner, S., Troyer Moore, C. & Stacy, E. (2020). A Majority of India’s Poor Women May Miss COVID-19 PMJDY Cash Transfers. Yale Economic Growth Center. Retrieved from <a target="_blank" rel="noreferrer" href="https://egc.yale.edu/reaching-indias-poorest-women-covid-19-relief">https://egc.yale.edu/reaching-indias-poorest-women-covid-19-relief</a></li>
						<li>PARIS21. (2020). Partner Report on Support to Statistics 2020. Retrieved from <a target="_blank" rel="noreferrer" href="https://paris21.org/sites/default/files/inline-files/PRESS%202020%20Final.pdf">https://paris21.org/sites/default/files/inline-files/PRESS%202020%20Final.pdf</a></li>
						<li>Rao, S., Gammage, S., Arnold, J., & Anderson, E. (2021). Human Mobility, COVID-19, and Policy Responses: The Rights and Claims-Making of Migrant Domestic Workers. Feminist Economics, Vol. 27 (1-2), 254-70. Retrieved from <a target="_blank" rel="noreferrer" href="https://doi/full/10.1080/13545701.2020.1849763">https://doi/full/10.1080/13545701.2020.1849763</a></li>
						<li>Rogan (2021, forthcoming). The Impact of the COVID-19 Crisis on the Informal Economy: Findings from a 12 City Study.</li>
						<li>Rosser, E., Morgan, R., Tan, H-L., Hawkins, K., Ngunjiri, A., Oyekunle, A., Schall, B., Pimenta, D., Tamaki, E., Rocha, M., & Wenham, C. (2021). How to Create a Gender-Responsive Pandemic Plan. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.genderandcovid-19.org/wp-content/uploads/2021/01/How-to-create-a-gender-responsive-pandemic-plan.pdf">https://www.genderandcovid-19.org/wp-content/uploads/2021/01/How-to-create-a-gender-responsive-pandemic-plan.pdf</a></li>
						<li>Sainath, P. (2014). Visible Work, Invisible Women—A Lifetime Bending. PARI. Retrieved from <a target="_blank" rel="noreferrer" href="https://ruralindiaonline.org/en/articles/visible-work-invisible-women-a-lifetime-bending-panel-2/">https://ruralindiaonline.org/en/articles/visible-work-invisible-women-a-lifetime-bending-panel-2/</a></li>
						<li>Salvador, S. (2019). The National Integrated Care System in Uruguay: An Opportunity for the Economic Empowerment of Women. UN Women. Retrieved from <a target="_blank" rel="noreferrer" href="https://www2.unwomen.org/-/media/field%20office%20americas/documentos/publicaciones/2019/10/snic%20web%20ingles.pdf?la=en&vs=2843">https://www2.unwomen.org/-/media/field%20office%20americas/documentos/publicaciones/2019/10/snic%20web%20ingles.pdf?la=en&vs=2843</a></li>
						<li>Save the Children. (2020). COVID-19 Places Half a Million More Girls at Risk of Child Marriage in 2020. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.savethechildren.org/us/about-us/media-and-news/2020-press-releases/covid19-half-a-million-more-girls-risk-of-child-marriage">https://www.savethechildren.org/us/about-us/media-and-news/2020-press-releases/covid19-half-a-million-more-girls-risk-of-child-marriage</a></li>
						<li>Saxena, N. (2012). Women, Land, and Agriculture in Rural India. UN Women. Retrieved from <a target="_blank" rel="noreferrer" href="https://asiapacific.unwomen.org/-/media/field%20office%20eseasia/docs/publications/2015/southasia/reportstudies/06_economic%20empowerment/un_women_land_agriculture_in_rural_india%20pdf.ashx?la=en">https://asiapacific.unwomen.org/-/media/field%20office%20eseasia/docs/publications/2015/southasia/reportstudies/06_economic%20empowerment/un_women_land_agriculture_in_rural_india%20pdf.ashx?la=en</a></li>
						<li>SEWA Bharat (b). (2020). Protecting Livelihoods. Retrieved from <a target="_blank" rel="noreferrer" href="https://sewabharatresearch.org/wp-content/uploads/2020/10/Protection-of-Livelihoods-SEWA-Data-September-2020.pdf">https://sewabharatresearch.org/wp-content/uploads/2020/10/Protection-of-Livelihoods-SEWA-Data-September-2020.pdf</a></li>
						<li>SEWA Federation. (2020). Women’s Cooperatives and COVID-19: Learnings and Way Forward. SEWA Cooperative Federation. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.sewafederation.org/wp-content/uploads/2020/12/Women-Cooperatives-and-COVID-19_SEWAFed-2020.pdf">https://www.sewafederation.org/wp-content/uploads/2020/12/Women-Cooperatives-and-COVID-19_SEWAFed-2020.pdf</a></li>
						<li>Smith, F. (2019). Using Data to Address the Unpaid Work Burden: A Case Study of Finland. Data2x. Retrieved from <a target="_blank" rel="noreferrer" href="https://data2x.org/wp-content/uploads/2020/01/Impact-Case-Studies-Finland-6P.pdf">https://data2x.org/wp-content/uploads/2020/01/Impact-Case-Studies-Finland-6P.pdf</a></li>
						<li>Solomon, A., Hawkins, K., & Morgan, R. (2020). Hawaii and Canada: Providing Lessons for Feminist Economic Recovery from COVID-19. The Gender and COVID-19 Working Group. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.genderandcovid-19.org/wp-content/uploads/2020/10/Gender-and-COVID-19-feminist-recovery-plans.pdf">https://www.genderandcovid-19.org/wp-content/uploads/2020/10/Gender-and-COVID-19-feminist-recovery-plans.pdf</a></li>
						<li>Stanley, V., Prettitore, P. (2020). How COVID-19 Puts Women’s Housing, Land, and Property Rights at Risk. World Bank Blogs. Retrieved from <a target="_blank" rel="noreferrer" href="https://blogs.worldbank.org/sustainablecities/how-covid-19-puts-womens-housing-land-and-property-rights-risk">https://blogs.worldbank.org/sustainablecities/how-covid-19-puts-womens-housing-land-and-property-rights-risk</a></li>
						<li>Time Use Surveys Measure Unpaid Work. Data2x. Retrieved from <a target="_blank" rel="noreferrer" href="https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf">https://data2x.org/wp-content/uploads/2019/05/Data2X-Invisible-No-More-Volume-1.pdf</a></li>
						<li>Torres, J., Maduko, F., Gaddis, I., Iacovone, L., & Beegle, K. (2021). The Impact of the COVID-19 Pandemic on Women-Led Businesses. Working Paper, World Bank.</li>
						<li>UN Women. (2018). Turning Promises into Action: Gender Equality in the 2030 Agenda for Sustainable Development. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.unwomen.org/en/digital-library/publications/2018/2/gender-equality-in-the-2030-agenda-for-sustainable-development-2018">https://www.unwomen.org/en/digital-library/publications/2018/2/gender-equality-in-the-2030-agenda-for-sustainable-development-2018</a></li>
						<li>UN Women. (2020). Universal Health Coverage, Gender Equality and Social Protection. Discussion Paper. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/discussion-paper-universal-health-coverage-gender-equality-and-social-protection-en.pdf?la=en&vs=5225">https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2020/discussion-paper-universal-health-coverage-gender-equality-and-social-protection-en.pdf?la=en&vs=5225</a></li>
						<li>UNDP. (2020). COVID-19 Global Gender Response Tracker Fact Sheets. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html">https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/COVID-19-Global-Gender-Response-Tracker.html</a></li>
						<li>UNDP. (2020). Justice for Women Amidst COVID-19. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/justice-for-women-amidst-covid-19.html">https://www.undp.org/content/undp/en/home/librarypage/womens-empowerment/justice-for-women-amidst-covid-19.html</a></li>
						<li>UNECE. (2020). COVID-19 Response Policies and the Care Economy: Mapping Economic and Social Policies in the ECE Region. Retrieved from <a target="_blank" rel="noreferrer" href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf">https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf</a></li>
						<li>UNECE. (2020). Women’s Economic Empowerment and the Care Economy in the ECE Region: The Impact of Economic and Social Policies During the COVID-19 Response and Recovery. UNECE Policy Brief. Retrieved from <a target="_blank" rel="noreferrer" href="https://unece.org/sites/default/files/2021-01/PBrief_Care%20Economy_Covid%20response_08012021_1.pdf">https://unece.org/sites/default/files/2021-01/PBrief_Care%20Economy_Covid%20response_08012021_1.pdf</a></li>
						<li>UNECE. (2021). COVID-19 Response Policies and the Care Economy: Mapping Economic and Social Policies in the ECE Region. Retrieved from <a target="_blank" rel="noreferrer" href="https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf">https://unece.org/sites/default/files/2021-01/Mapping%20ECE%20Care%20COVID_final_SDGU_with%20covers.pdf</a></li>
						<li>UNESCO. (2020). #LearningNeverStops: COVID-19 Education Response. Retrieved from <a target="_blank" rel="noreferrer" href="https://en.unesco.org/covid19/educationresponse/globalcoalition">https://en.unesco.org/covid19/educationresponse/globalcoalition</a></li>
						<li>United Nations Women. (2018). Turning Promises into Action: Gender Equality in the 2030 Agenda for Sustainable Development. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2018/sdg-report-gender-equality-in-the-2030-agenda-for-sustainable-development-2018-en.pdf?la=en&vs=4332">https://www.unwomen.org/-/media/headquarters/attachments/sections/library/publications/2018/sdg-report-gender-equality-in-the-2030-agenda-for-sustainable-development-2018-en.pdf?la=en&vs=4332</a></li>
						<li>United Nations Women. (2020). Whose Time to Care? Unpaid Care and Domestic Work During COVID-19. Retrieved from <a target="_blank" rel="noreferrer" href="https://data.unwomen.org/sites/default/files/inline-files/Whose-time-to-care-brief_0.pdf">https://data.unwomen.org/sites/default/files/inline-files/Whose-time-to-care-brief_0.pdf</a></li>
						<li>United Nations. (2020). Shared Responsibility, Global Solidarity: Responding to the Socio-Economic Impacts of COVID-19. Retrieved from <a target="_blank" rel="noreferrer" href="https://unsdg.un.org/resources/shared-responsibility-global-solidarity-responding-socio-economic-impacts-covid-19">https://unsdg.un.org/resources/shared-responsibility-global-solidarity-responding-socio-economic-impacts-covid-19</a></li>
						<li>Vijayasingham, L., Govender, V., Witter, S., & Remme, M. (2020). Employment Based Health Financing Does Not Support Gender Equity in Universal Health Coverage. BMJ, Vol. 371. Retrieved from <a target="_blank" rel="noreferrer" href="https://doi.org/10.1136/bmj.m3384">https://doi.org/10.1136/bmj.m3384</a></li>
						<li>WIEGO. (2020). COVID-19 Crisis and the Informal Economy: Informal Workers in Delhi, India. Retrieved from <a target="_blank" rel="noreferrer" href="https://www.wiego.org/sites/default/files/publications/file/WIEGO_FactSheet_Delhi_for%20Web.pdf">https://www.wiego.org/sites/default/files/publications/file/WIEGO_FactSheet_Delhi_for%20Web.pdf</a></li>
						<li>Women’s Budget Group. (2020). Creating a Caring Economy: A Call to Action. Retrieved from <a target="_blank" rel="noreferrer" href="https://wbg.org.uk/wp-content/uploads/2020/10/WBG-Report-v10.pdf">https://wbg.org.uk/wp-content/uploads/2020/10/WBG-Report-v10.pdf</a></li>
						<li>World Bank Group. (2019). Women, Business and the Law 2019. Retrieved from <a target="_blank" rel="noreferrer" href="https://pubdocs.worldbank.org/en/459771566827285080/WBL-Child-Care-4Pager-WEB.pdf">https://pubdocs.worldbank.org/en/459771566827285080/WBL-Child-Care-4Pager-WEB.pdf</a></li>
						<li>Zimmerman, Jamie., May, Ma., Kellison, E., & Klugman, J. (2020). Digital Cash Transfers in the Time of COVID-19: Opportunities and Considerations for Women’s Inclusion and Empowerment. Retrieved from <a target="_blank" rel="noreferrer" href="https://documents1.worldbank.org/curated/en/378931596643390083/pdf/Digital-Cash-Transfers-in-Times-of-COVID-19-Opportunities-and-Considerations-for-Womens-Inclusion-and-Empowerment.pdf">https://documents1.worldbank.org/curated/en/378931596643390083/pdf/Digital-Cash-Transfers-in-Times-of-COVID-19-Opportunities-and-Considerations-for-Womens-Inclusion-and-Empowerment.pdf</a></li>
					</ul>
				</ContainedContent>
			</FullWidthSection>
		);
	}
}

export default ExecutiveSummary;
