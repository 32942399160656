import React from "react";
import SlickSlider from "react-slick";
import styles from './SlickSliderCaregiving.module.scss';

import image_1 from '../../images/slideshow/1-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_2 from '../../images/slideshow/2-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_3 from '../../images/slideshow/3-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_4 from '../../images/slideshow/4-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_5 from '../../images/slideshow/5-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_6 from '../../images/slideshow/6-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_7 from '../../images/slideshow/7-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_8 from '../../images/slideshow/8-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_9 from '../../images/slideshow/9-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_10 from '../../images/slideshow/10-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_11 from '../../images/slideshow/11-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_12 from '../../images/slideshow/12-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_13 from '../../images/slideshow/13-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_14 from '../../images/slideshow/14-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';
import image_15 from '../../images/slideshow/15-Kidogo-Kenya-caregiving-Tobin_Jones_Kidogo_Childcare.jpg';

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    adaptiveHeight: true
};

class SlickSliderCaregiving extends React.Component {
	render() {
          return (
            <div className={styles.SliderWrapper} style={{ 
				backgroundColor: this.props.color
			}}>

                <div className="tight-slider">
                    <SlickSlider {...settings}>

                        <div className="CaregivingSlide">
                            <img src={image_1} alt="A young boy throws a block of wood at two pins during breaktime at one of Kidogo's child care centers in Kangemi on the outskirts of Nairobi." />
                            <p className="caption">A young boy throws a block of wood at two pins during breaktime at one of Kidogo's child care centers in Kangemi on the outskirts of Nairobi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_2} alt="Dorcas Kithome, a teacher of 3- and 4-year-olds at a Kidogo child care center in Kangemi, plays with her students during class." />
                            <p className="caption">Dorcas Kithome, a teacher of 3- and 4-year-olds at a Kidogo child care center in Kangemi, plays with her students during class.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_3} alt="Young students take part in a class activity at a Kidogo center in Kangemi. " />
                            <p className="caption">Young students take part in a class activity at a Kidogo center in Kangemi. </p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_4} alt="A student washes his hands before heading into lunch at a Kidogo child care center in Kangemi." />
                            <p className="caption">A student washes his hands before heading into lunch at a Kidogo child care center in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_5} alt="Students in a class for 5-year-olds work on arts and crafts at one of Kidogo’s child care centers in Kangemi." />
                            <p className="caption">Students in a class for 5-year-olds work on arts and crafts at one of Kidogo’s child care centers in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_6} alt="A girl leads a song-and-dance routine at one of Kidogo's child care centers in Kangemi. " />
                            <p className="caption">A girl leads a song-and-dance routine at one of Kidogo's child care centers in Kangemi. </p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_7} alt="Girls hold hands during breaktime at one of Kidogo's child care centers in Kangemi." />
                            <p className="caption">Girls hold hands during breaktime at one of Kidogo's child care centers in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_8} alt="A girl skips rope at one of Kidogo's child care centers in Kangemi. " />
                            <p className="caption">A girl skips rope at one of Kidogo's child care centers in Kangemi. </p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_9} alt="A group of girls play a game during breaktime at at one of Kidogo's child care centers in Kangemi." />
                            <p className="caption">A group of girls play a game during breaktime at at one of Kidogo's child care centers in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_10} alt="Anne Keverenge, a teacher at a Kidogo child care center in Kangemi, plays with children during breaktime." />
                            <p className="caption">Anne Keverenge, a teacher at a Kidogo child care center in Kangemi, plays with children during breaktime.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_11} alt="Students play during class at a Kidogo child care center in Kangemi." />
                            <p className="caption">Students play during class at a Kidogo child care center in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_12} alt="Students play during class at a Kidogo child care center in Kangemi." />
                            <p className="caption">Students play during class at a Kidogo child care center in Kangemi.</p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_13} alt="Shoes lay scattered outside a classroom at a Kidogo child care center in Kangemi. " />
                            <p className="caption">Shoes lay scattered outside a classroom at a Kidogo child care center in Kangemi. </p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_14} alt="A group of girls lie down for their post-lunch nap at a Kidogo child care center in Kangemi. " />
                            <p className="caption">A group of girls lie down for their post-lunch nap at a Kidogo child care center in Kangemi. </p>
                        </div>
                        <div className="CaregivingSlide">
                            <img src={image_15} alt="A young student sits in the middle of a class for students ages 6 months to 3 years while another makes a dash for the door at a Kidogo center in Kangemi. " />
                            <p className="caption">A young student sits in the middle of a class for students ages 6 months to 3 years while another makes a dash for the door at a Kidogo center in Kangemi. </p>
                        </div>
                    </SlickSlider>
                </div>
            </div>
          );
	}
}

export default SlickSliderCaregiving;
